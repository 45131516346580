import { defineMessages } from 'react-intl';

const messages = defineMessages({
  defaultErrorHeadline: {
    id: 'sambla.se.errorBoundary.defaultErrorHeadline',
    defaultMessage: 'Something went wrong!',
    description: 'The default error message when something has failed - Oops, something went wrong',
  },
  defaultErrorMessage: {
    id: 'sambla.se.errorBoundary.defaultErrorMessage',
    defaultMessage: 'We currently have technical problems. Please contact customer support for assistance or try again!',
    description: 'The default error message when something has failed - Oops, something went wrong',
  },
  defaultErrorMessageWithPhoneNumber: {
    id: 'sambla.se.errorBoundary.defaultErrorMessageWithPhoneNumber',
    defaultMessage: 'We currently have technical problems. Click the button below and we will call you as soon as we can on <i> {customerPhoneNumber} </i> to continue your application',
    description: 'The default error message when something has failed - Oops, something went wrong',
  },
  emailSubmissionInputPlaceholder: {
    id: 'sambla.se.errorBoundary.emailSubmissionInputPlaceholder',
    defaultMessage: 'Your email adress',
    description: 'The default input placeholder for Email Submissionform',
  },
  emailSubmissionButtonLabel: {
    id: 'sambla.se.errorBoundary.emailSubmissionButtonLabel',
    defaultMessage: 'Email Submission',
    description: 'The default button label for Email Submission form',
  },
  emailSubmissionSuccessMessage: {
    id: 'sambla.se.errorBoundary.emailSubmissionSuccessMessage',
    defaultMessage: 'Thank you!',
    description: 'Success message on the "Email Submission"-button in the form',
  },
  emailSubmissionErrorMessage: {
    id: 'sambla.se.errorBoundary.emailSubmissionErrorMessage',
    defaultMessage: 'We could not send your email adress, please try again later!',
    description: 'Error message on the "Email Submission"-button in the form',
  },
  safeAndSecure: {
    id: 'sambla.se.safeAndSecure',
    defaultMessage: 'Safe and secure',
    description: 'Label for lock seal on the bottom of the form',
  },
  totalLoanUseOnlyNumbers: {
    id: 'sambla.se.totalLoanUseOnlyNumbers',
    defaultMessage: 'Use numbers please.',
    description: 'Informing the customer/applicant that only numbers are allowed for the total loan amount',
  },
  tooLowTotalLoan: {
    id: 'sambla.se.tooLowTotalLoan',
    defaultMessage: 'Lowest loan amount is {minAllowedAmount, number, currency}.',
    description: 'Informing the customer/applicant that they have provided a loan amount to low. Also show what the minimum loan amount is',
  },
  tooHighTotalLoan: {
    id: 'sambla.se.tooHighTotalLoan',
    defaultMessage: 'Highest loan amount is {maxAllowedAmount, number, currency}.',
    description: 'Informing the customer/applicant that they have provided a loan amount to high. Also show what the maximum loan amount is',
  },
  tooHighCurrentLoan: {
    id: 'sambla.se.tooHighCurrentLoan',
    defaultMessage: 'Highest loan amount is {maxLimit, number, currency}.',
    description: 'Error message informing the applicant that loan amount is to high',
  },
  textInputIsRequired: {
    id: 'sambla.se.textInputIsRequired',
    defaultMessage: 'This field can not be left empty',
    description: '',
  },
  buttonGroupIsRequired: {
    id: 'sambla.se.buttonGroupIsRequired',
    defaultMessage: 'Please select one.',
    description: 'Tells the customer that one option needs to be selected',
  },
  checkBoxIsRequired: {
    id: 'sambla.se.checkBoxIsRequired',
    defaultMessage: 'Please consent to our terms and conditions',
    description: 'Tells the customer that checkox needs to be checked',
  },
  repaymentTimeUseOnlyNumbers: {
    id: 'sambla.se.repaymentTimeUseOnlyNumbers',
    defaultMessage: 'Use numbers please.',
    description: 'Inform the customer that they are only allowed to use numbers',
  },
  tooShortRepaymentTime: {
    id: 'sambla.se.tooShortRepaymentTime',
    defaultMessage: 'Shortest possible repayment time is {loanMinYears} year.',
    description: 'Inform the customer that they have choosen a repayment time shorter than allowed',
  },
  tooLongRepaymentTime: {
    id: 'sambla.se.tooLongRepaymentTime',
    defaultMessage: 'Longest possible repayment time is {loanMaxYears} years.',
    description: 'Inform the customer thath they have choosen a repayment time further ahead than allowed',
  },
  purposeIsRequired: {
    id: 'sambla.se.purposeIsRequired',
    defaultMessage: 'Please choose a loan purpose.',
    description: 'Informing the customer that a cause/reason for the loan must be passed in',
  },
  numCurrentLoansIsRequired: {
    id: 'sambla.se.numCurrentLoansIsRequired',
    defaultMessage: 'Number of current loans are required.',
    description: 'Error message informing the applicant that the number of current loan must be provided',
  },
  numCurrentLoansUseOnlyNumbers: {
    id: 'sambla.se.numCurrentLoansUseOnlyNumbers',
    defaultMessage: 'Use numbers please.',
    description: 'Error message informing the applicant that only numbers are allowed',
  },
  tooLowNumCurrentLoans: {
    id: 'sambla.se.tooLowNumCurrentLoans',
    defaultMessage: 'Enter number of credits (0-10).',
    description: 'Error message informing the applicant that the number of current loans provided is to low',
  },
  tooHighNumCurrentLoans: {
    id: 'sambla.se.tooHighNumCurrentLoans',
    defaultMessage: 'Enter number of credits (0-10).',
    description: 'Error message informing the applicant that the number of current loands provided is to high',
  },
  newLoanUseOnlyNumbers: {
    id: 'sambla.se.newLoanUseOnlyNumbers',
    defaultMessage: 'Use numbers please.',
    description: 'Inform the customer/applicant that they are only allowed to use numbers',
  },
  tooLowNewLoan: {
    id: 'sambla.se.tooLowNewLoan',
    defaultMessage: 'Lowest loan amount is {minAllowedAmount, number, currency}.',
    description: 'When a customer/applicant has filled in an amount lower than minimum amount allowed',
  },
  tooHighNewLoan: {
    id: 'sambla.se.tooHighNewLoan',
    defaultMessage: 'Highest loan amount is {maxAllowedAmount, number, currency}.',
    description: 'When a customer/applicant has filled in an amount higher than maximum amount allowed',
  },
  mergeLoanUseOnlyNumbers: {
    id: 'sambla.se.mergeLoanUseOnlyNumbers',
    defaultMessage: 'Use numbers please.',
    description: 'Error message informing the applicant that only numbers are allowed to be provided',
  },
  tooLowMergeLoan: {
    id: 'sambla.se.tooLowMergeLoan',
    defaultMessage: 'Lowest loan amount is {minAllowedAmount, number, currency}.',
    description: 'Error message informing the applicant that the loan amount is to low and what the minimum requirements are',
  },
  tooHighMergeLoan: {
    id: 'sambla.se.tooHighMergeLoan',
    defaultMessage: 'Highest loan amount is {maxAllowedAmount, number, currency}.',
    description: 'Error message informing the applicant that the loan amount is to high and what the maximum requirements are',
  },
  mergeLoanIsNotABoolean: {
    id: 'sambla.se.mergeLoanIsNotABoolean',
    defaultMessage: 'Please choose if you want to consolidate loans',
    description: 'Error message informing that merge is not of type boolean',
  },
  interestRateUseOnlyNumbers: {
    id: 'sambla.se.interestRateUseOnlyNumbers',
    defaultMessage: 'Please use only numbers',
    description: 'Informing the customer/applicant that only numbers are allowed for the interest rate',
  },
  tooLowInterestRate: {
    id: 'sambla.se.tooLowInterestRate',
    defaultMessage: 'The lowest effective interest rate you can use is {minAllowedAmount} %.',
    description: 'Informing the customer/applicant that they have provided a interest rate to low. Also show what the minimum interest rate is',
  },
  tooHighInterestRate: {
    id: 'sambla.se.tooHighInterestRate',
    defaultMessage: 'The highest effective interest rate you can use is {maxAllowedAmount} %.',
    description: 'Informing the customer/applicant that they have provided a interest rate to high. Also show what the maximum interest rate is',
  },
  interestRateHasTwoDots: {
    id: 'sambla.se.interestRateHasTwoDots',
    defaultMessage: "Please check if you've added too many dots as separators",
    description: 'Informing the customer/applicant that they have...',
  },
  nothingBeforeDotInInterestRate: {
    id: 'sambla.se.nothingBeforeDotInInterestRate',
    defaultMessage: 'Please enter a number before the separator',
    description: 'Informing the customer/applicant that they have...',
  },
  tooManyDecimalsInInterestRate: {
    id: 'sambla.se.tooManyDecimalsInInterestRate',
    defaultMessage: "Please check if you've added too many decimals",
    description: 'Informing the customer/applicant that they have...',
  },
  homeTypeIsRequired: {
    id: 'sambla.se.homeTypeIsRequired',
    defaultMessage: 'Please enter a housing situation.',
    description: 'Error message informing applicant that a type of housing must be provided',
  },
  employmentTypeIsRequired: {
    id: 'sambla.se.employmentTypeIsRequired',
    defaultMessage: 'Please enter an employment type',
    description: 'Inform the customer that a employment type must be provided',
  },
  employedToMonthIsRequired: {
    id: 'sambla.se.employedToMonthIsRequired',
    defaultMessage: 'Month is required.',
    description: 'Error message informing the applicant that an "employed until"-month must be selected',
  },
  employedToRequiredYear: {
    id: 'sambla.se.employedToRequiredYear',
    defaultMessage: 'Year is required.',
    description: 'Error message informing the applicant that a year must be filled in for the field "employed untill the year of"',
  },
  employedToRequiredMonth: {
    id: 'sambla.se.employedToRequiredMonth',
    defaultMessage: 'Month is required.',
    description: 'Error message informing the applicant that a month must be filled in for the field "employed untill the month of',
  },
  employedToUseOnlyNumbers: {
    id: 'sambla.se.employedToUseOnlyNumbers',
    defaultMessage: 'Enter when your project employment ends, only digits (i.e. {nextYear}).',
    description: 'Error message informing the applicant that they need to fill in when their employment ends, only in numbers',
  },
  employedToFormat: {
    id: 'sambla.se.employedToFormat',
    defaultMessage: 'Enter the year with four digits (i.e. {nextYear})',
    description: 'Error message reminding the customer that the year needs to be in the correct format (xxxx)',
  },
  employedToDateIsInThePast: {
    id: 'sambla.se.employedToDateIsInThePast',
    defaultMessage: 'The end date must be a future date.',
    description: 'Error message informing the applicant that the choosen date was set to a date in the past',
  },
  employedToDateTooFarInTheFuture: {
    id: 'sambla.se.employedToDateTooFarInTheFuture',
    defaultMessage: 'The end date is too far in the future',
    description: 'Error message informing the applicant that the choosen date that is more than 100 years in the future',
  },
  employedSinceMonthRequired: {
    id: 'sambla.se.employedSinceMonthRequired',
    defaultMessage: 'Month is required.',
    description: 'Error message informing the applicant that an "employed since"-month must be selected',
  },
  requiredDependants: {
    id: 'sambla.se.requiredDependants',
    defaultMessage: 'Please enter number of children.',
    description: 'Error message informing the applicant that the number of dependants/kids havent been filled in',
  },
  dependantsUseOnlyNumbers: {
    id: 'sambla.se.dependantsUseOnlyNumbers',
    defaultMessage: 'Use numbers please.',
    description: 'Error message informing the applicant that they may only use numbers when filling in the number of dependants/kids',
  },
  tooLowDependants: {
    id: 'sambla.se.tooLowDependants',
    defaultMessage: 'Enter number of children.',
    description: 'Error message informing the applicant that they have filled in a too low of an amount of dependants',
  },
  tooHighDependants: {
    id: 'sambla.se.tooHighDependants',
    defaultMessage: 'Enter number of children.',
    description: 'Error message informing the applicant that they have filled in a too high of an amount of dependants',
  },
  consolidateLoanUseOnlyNumber: {
    id: 'sambla.se.consolidateLoanUseOnlyNumber',
    defaultMessage: 'Use numbers please.',
    description: 'Asking the customer/applicant to only use numbers for the input',
  },
  tooHighConsolidateLoan: {
    id: 'sambla.se.tooHighConsolidateLoan',
    defaultMessage: 'Highest loan amount is {maxAllowedAmount, number, currency}.',
    description: 'Informing the customer/applicant that they have entered a loan amount to high. Also shows the max allowed amount',
  },
  requiredCivilStatus: {
    id: 'sambla.se.requiredCivilStatus',
    defaultMessage: 'Please enter a martial status.',
    description: 'Error message informing the applicant that their civil state must be provided',
  },
  phoneNumberRequired: {
    id: 'sambla.se.phoneNumberRequired',
    defaultMessage: 'Please enter your phone number',
    description: '',
  },
  phoneNumberMobilePrefixError: {
    id: 'sambla.se.phoneNumberMobilePrefixError',
    defaultMessage: 'Your phone number must begin with 07, +46 or 0046.',
    description: '',
  },
  wrongPhoneNumberNumber: {
    id: 'sambla.se.wrongPhoneNumberNumber',
    defaultMessage: 'The phone number appears to be incorrect. Please enter a valid Swedish mobile phone number.',
  },
  wrongPhoneNumberBeginning: {
    id: 'sambla.se.wrongPhoneNumberBeginning',
    defaultMessage: "The phone number is too long. Verify that you haven't added a digit too many",
    description: 'Error message for when the customer fills in a too long phone number.',
  },
  missingOccupation: {
    id: 'sambla.se.missingOccupation',
    defaultMessage: 'Please enter your occupation',
    description: 'Error message',
  },
  requiredNationalId: {
    id: 'sambla.se.requiredNationalId',
    defaultMessage: 'A national ID is required.',
    description: 'Error message informing the applicant that a social security number/national ID must be provided',
  },
  wrongNationalIdFormat: {
    id: 'sambla.se.wrongNationalIdFormat',
    defaultMessage: 'Wrong format, for example: 131159-2950.',
    description: 'Error message informing the applicant that the social security number/national ID is wrongly formatted',
  },
  tooYoungNationalId: {
    id: 'sambla.se.tooYoungNationalId',
    defaultMessage: 'You have to be at least {minAge} years of age to submit an application.',
    description: 'Error message informing the applicant that the applicant/customer has provided an age to low required to make an application',
  },
  tooOldNationalId: {
    id: 'sambla.se.tooOldNationalId',
    defaultMessage: 'Unfortunately, we can only help people up to {maxAge} years of age.',
    description: 'Error message informing the applicant that the applicant/customer has provided an age to high required to make an application',
  },
  invalidNationalId: {
    id: 'sambla.se.invalidNationalId',
    defaultMessage: 'Invalid national ID.',
    description: 'Error message informing the applicant that the social security number/national ID is invalid',
  },
  employerPhoneMissingPhoneNumber: {
    id: 'sambla.se.employerPhoneMissingPhoneNumber',
    defaultMessage: 'Please enter a phone number',
    description: '',
  },
  requiredBankAccount: {
    id: 'sambla.se.requiredBankAccount',
    defaultMessage: 'Bank account number is required.',
    description: 'Error message informing the applicant that a bank account number must be provided',
  },
  invalidBankAccountFormat: {
    id: 'sambla.se.invalidBankAccountFormat',
    defaultMessage: 'Wrong format.',
    description: 'Error message informing the applicant that the bank account number is wrongly formatted',
  },
  invalidBankAccount: {
    id: 'sambla.se.invalidBankAccount',
    defaultMessage: 'Invalid bank account number',
    description: 'Error message informing the applicant that the bank account number is invalid',
  },
  coApplicantEmployedToYearPlaceholder: {
    id: 'sambla.se.coApplicantEmployedToYearPlaceholder',
    defaultMessage: 'Year',
    description: 'Default text for coApplicant "employed until"-input in years, placed inside form',
  },
  coApplicantEmployedToYearLabel: {
    id: 'sambla.se.coApplicantEmployedToYearLabel',
    defaultMessage: 'Employed to (year)',
    description: 'Label for coApplicant "employed until"-input in years, placed inside form',
  },
  coApplicantEmployedToMonthLabel: {
    id: 'sambla.se.coApplicantEmployedToMonthLabel',
    defaultMessage: 'Employed to (month)',
    description: 'Default text for coApplicant "employed until"-input in months, placed inside form',
  },
  coApplicantEmployedToMonthPlaceholder: {
    id: 'sambla.se.coApplicantEmployedToMonthPlaceholder',
    defaultMessage: 'Month',
    description: 'Default text for coApplicant "employed until"-input in months, placed inside form',
  },
  coApplicantEmployedToTooltip: {
    id: 'sambla.se.coApplicantEmployedToTooltip',
    defaultMessage: 'Enter when their employment ends.',
    description: 'Tooltip for coApplicant "employed until"-input, placed inside form',
  },
  employedToMonthLabel: {
    id: 'sambla.se.employedToMonthLabel',
    defaultMessage: 'Employed to (month)',
    description: 'Default text for "employed until"-input in months, placed inside form',
  },
  employedToYearPlaceholder: {
    id: 'sambla.se.employedToYearPlaceholder',
    defaultMessage: 'Year',
    description: 'Default text for "employed until"-input in years, placed inside form',
  },
  employedToYearLabel: {
    id: 'sambla.se.employedToYearLabel',
    defaultMessage: 'Employed to (year)',
    description: 'Label for "employed until"-input in years, placed inside form',
  },
  employedToMonthPlaceholder: {
    id: 'sambla.se.employedToMonthPlaceholder',
    defaultMessage: 'Month',
    description: 'Default text for "employed until"-input in months, placed inside form',
  },
  employedToTooltip: {
    id: 'sambla.se.employedToTooltip',
    defaultMessage: 'Enter when your employment ends.',
    description: 'Tooltip for "employed until"-input, placed inside form',
  },
  retiredSinceDynamicLabel: {
    id: 'sambla.se.retiredSinceDynamicLabel',
    defaultMessage: 'Retired since',
    description: 'Dynamic label for employedSinceYear',
  },
  disabilityPensionSinceDynamicLabel: {
    id: 'sambla.se.disabilityPensionSinceDynamicLabel',
    defaultMessage: 'Disability pensioner since',
    description: 'Dynamic label for employedSinceYear',
  },
  employedSinceMonthplaceholder: {
    id: 'sambla.se.employedSinceMonthplaceholder',
    defaultMessage: 'Month',
    description: 'Default text for "employed since"-input in months, placed inside form',
  },
  nextSlide: {
    id: 'sambla.se.nextSlide',
    defaultMessage: 'Next',
    description: 'general progress button label',
  },
  slideTitleOne: {
    id: 'sambla.se.slideTitleOne',
    defaultMessage: 'Your details',
    description: 'First slide title',
  },
  slideTitleTwo: {
    id: 'sambla.se.slideTitleTwo',
    defaultMessage: 'Employment',
    description: '',
  },
  slideTitleThree: {
    id: 'sambla.se.slideTitleThree',
    defaultMessage: 'Housing',
    description: '',
  },
  slideTitleFour: {
    id: 'sambla.se.slideTitleFour',
    defaultMessage: "Co-applicant's details",
    description: '',
  },
  repaymentTimePlaceholder: {
    id: 'sambla.se.repaymentTimePlaceholder',
    defaultMessage: '0',
    description: 'Default text for repayment time inside form',
  },
  repaymentTimeLabel: {
    id: 'sambla.se.repaymentTimeLabel',
    defaultMessage: 'Repayment time',
    description: 'Label for repayment time inside form',
  },
  repaymentTimeSuffix: {
    id: 'sambla.se.repaymentTimeSuffix',
    defaultMessage: 'years',
    description: 'Suffix for repayment time inside form',
  },
  repaymentTimeTooltip: {
    id: 'sambla.se.repaymentTimeTooltip',
    defaultMessage: 'For how long do you want to repay the loan? The longer the period, the lower your monthly cost will be.',
    description: 'Tooltip for repayment time inside form, asking the applicant to fill in how long they wish to take to repay their loan. Also informing them that the longer time of repayment, the lower the monthly cost',
  },
  addCoApplicantLabel: {
    id: 'sambla.se.addCoApplicantLabel',
    defaultMessage: 'Do you want to increase your chances of receiving more favorable offers by adding a co-applicant?',
    description: 'Experiment-variant for add co-applicant option that the applicant can use if they wish to share their loan with a co-applicant',
  },
  addCoApplicantDesc: {
    id: 'sambla.se.addCoApplicantDesc',
    defaultMessage: 'Adding a co-applicant often gives you an increased chance of getting a loan, and often at a better interest rate than without a co-applicant!',
    description: 'Description in add co-applicant textbox',
  },
  addCoApplicantTooltip: {
    id: 'sambla.se.addCoApplicantTooltip',
    defaultMessage: 'Co-applicants provides the banks with better security as it is deemed a lower risk for the banks when there are two people applying for the loan. This in hand often gives more favorable terms and interest rates for the applicant(s), depending on your economical situation.',
    description: 'Tooltip for adding co-applicant',
  },
  purposeHeaderText: {
    id: 'sambla.se.purposeHeaderText',
    defaultMessage: 'Loan purpose',
    description: 'Header in the application form for the group of loan purpose related inputs - The purpose of the loan',
  },
  purposeTooltip: {
    id: 'sambla.se.purposeTooltip',
    defaultMessage: 'If you have more than one purpose for the loan, state the main purpose. "Services" can be for, for example, weddings, parties or cleaning jobs.',
    description: 'Tooltip for The purpose of the loan',
  },
  civilStatusPlaceholder: {
    id: 'sambla.se.civilStatusPlaceholder',
    defaultMessage: 'Please enter marital status',
    description: 'Default-text för civilstånd i formulär.',
  },
  civilStatusLabel: {
    id: 'sambla.se.civilStatusLabel',
    defaultMessage: 'Marital status',
    description: 'Label-text för civilstånd i formulär.',
  },
  civilStatusTooltip: {
    id: 'sambla.se.civilStatusTooltip',
    defaultMessage: 'Please enter your relationship status. If you are living separatly, use the option "Single',
    description: 'Tooltip-text civilstånd i formulär.',
  },
  civilStatusSingle: {
    id: 'sambla.se.civilStatusSingle',
    defaultMessage: 'Single',
    description: 'Marital status of the applicant - single',
  },
  civilStatusPartner: {
    id: 'sambla.se.civilStatusPartner',
    defaultMessage: 'Cohabitant',
    description: 'Marital status of the applicant - partner',
  },
  civilStatusMarried: {
    id: 'sambla.se.civilStatusMarried',
    defaultMessage: 'Married',
    description: 'Marital status of the applicant - married',
  },
  coApplicantCivilStatusLabel: {
    id: 'sambla.se.coApplicantCivilStatusLabel',
    defaultMessage: 'Marital status of co-applicant',
    description: 'Label for civil status of co-applicant',
  },
  coApplicantCivilStatusTooltip: {
    id: 'sambla.se.coApplicantCivilStatusTooltip',
    defaultMessage: 'Please enter the relationship status of your co-applicant. If he/she is living separatly to their partner, use option "Single".',
    description: 'Tooltip-text for coApplicant civil status in form.',
  },
  applicationLoanAmountPlaceholder: {
    id: 'sambla.se.applicationLoanAmountPlaceholder',
    defaultMessage: 'XX XXX',
    description: 'Default-text för totalbelopp av ett nuvarande kreditlån i formulär.',
  },
  applicationLoanAmountLabel: {
    id: 'sambla.se.applicationLoanAmountLabel',
    defaultMessage: 'Loan amount',
    description: 'Label-text för totalbelopp av ett nuvarande kreditlån i formulär.',
  },
  applicationLoanAmountSuffix: {
    id: 'sambla.se.applicationLoanAmountSuffix',
    defaultMessage: 'SEK',
    description: 'Suffix-text för totalbelopp av ett nuvarande kreditlån i formulär.',
  },
  applicationLoanAmountTooltip: {
    id: 'sambla.se.applicationLoanAmountTooltip',
    defaultMessage: 'Enter the amount for this loan.',
    description: 'Tooltip-text för totalbelopp av ett nuvarande kreditlån i formulär.',
  },
  currentLoanMergeLabel: {
    id: 'sambla.se.currentLoanMergeLabel',
    defaultMessage: 'I want to consolidate loans',
    description: 'Label-text för val av att lösa lån i formulär.',
  },
  currentLoanMergeTooltip: {
    id: 'sambla.se.currentLoanMergeTooltip',
    defaultMessage: 'Our partners can give you a more favorable interest rate if you chose to consolidate your loans. This way, they "buy out" parts or your whole loan situation and therefore merging them into a single loan',
    description: 'Tooltip-text för val av att lösa lån i formulär.',
  },
  dependantsLabel: {
    id: 'sambla.se.dependantsLabel',
    defaultMessage: 'Number of children under 21',
    description: 'Label-text för antal barn i formulär.',
  },
  dependantsTooltip: {
    id: 'sambla.se.dependantsTooltip',
    defaultMessage: 'Please enter the number of children living at home who are under 21 years old',
    description: 'Tooltip-text för antal barn i formulär.',
  },
  dependantsZero: {
    id: 'sambla.se.dependantsZero',
    defaultMessage: '0',
    description: 'No child',
  },
  dependantsOne: {
    id: 'sambla.se.dependantsOne',
    defaultMessage: '1',
    description: 'One child',
  },
  dependantsTwo: {
    id: 'sambla.se.dependantsTwo',
    defaultMessage: '2',
    description: 'Two children',
  },
  dependantsThree: {
    id: 'sambla.se.dependantsThree',
    defaultMessage: '3',
    description: 'Three children',
  },
  dependantsFour: {
    id: 'sambla.se.dependantsFour',
    defaultMessage: '4',
    description: 'Four children',
  },
  dependantsFive: {
    id: 'sambla.se.dependantsFive',
    defaultMessage: '5+',
    description: 'Five children',
  },
  coApplicantDependantsLabel: {
    id: 'sambla.se.coApplicantDependantsLabel',
    defaultMessage: 'Number of children under 21',
    description: 'Label text for number of children',
  },
  coApplicantDependantsTooltip: {
    id: 'sambla.se.coApplicantDependantsTooltip',
    defaultMessage: "Please enter the number of childrens living at your co-applicant's home who are under 21 years old",
    description: 'Tooltip-text for coApplicant dependants in form.',
  },
  emailPlaceholder: {
    id: 'sambla.se.emailPlaceholder',
    defaultMessage: 'Your email',
    description: 'Default-text för e-post i formulär.',
  },
  emailLabel: {
    id: 'sambla.se.emailLabel',
    defaultMessage: 'Email',
    description: 'Label-text för e-post i formulär.',
  },
  emailTooltip: {
    id: 'sambla.se.emailTooltip',
    defaultMessage: 'Your e-mail is required so we can contact and send out information regarding your application to you. If a bid is accepted, the banks will also communicate with you using this e-mail.',
    description: 'Tooltip-text för e-post i formulär.',
  },
  emailSuggestion: {
    id: 'sambla.se.emailSuggestion',
    defaultMessage: 'Did you mean',
    description: 'The pre-suggestion helptext at the email input field.',
  },
  emailMustBeUnique: {
    id: 'sambla.se.emailMustBeUnique',
    defaultMessage: 'The main applicant and the co-applicant cannot have the same email.',
    description: 'Informing the customer that the applicant and co-applicant can not use the same e-mail',
  },
  coApplicantEmailLabel: {
    id: 'sambla.se.coApplicantEmailLabel',
    defaultMessage: 'E-mail of co-applicant',
    description: 'Label-text för e-post i formulär.',
  },
  coApplicantEmailPlaceholder: {
    id: 'sambla.se.coApplicantEmailPlaceholder',
    defaultMessage: 'Co-applicant’s email',
    description: 'Default-text for coApplicant e-mail in form.',
  },
  coApplicantEmailTooltip: {
    id: 'sambla.se.coApplicantEmailTooltip',
    defaultMessage: "Your co-applicant's e-mail is needed for them to be able to take part of the loan offers",
    description: 'Tooltip-text for coApplicant e-mail in form.',
  },
  emailRequired: {
    id: 'sambla.se.emailRequired',
    defaultMessage: 'Please enter a valid e-mail',
    description: 'Error message informing the applicant that an email address must be provided',
  },
  emailNoSpaces: {
    id: 'sambla.se.emailNoSpaces',
    defaultMessage: 'The email cannot contain blank spaces.',
    description: 'Error message informing the applicant that the provided email address must not contain any spaces',
  },
  emailNoAt: {
    id: 'sambla.se.emailNoAt',
    defaultMessage: 'A valid e-mail needs to include @',
    description: 'Error message informing the applicant that the provided email address must contain the symbol "@"',
  },
  emailTooManyAts: {
    id: 'sambla.se.emailTooManyAts',
    defaultMessage: 'The email cannot contain more than one @.',
    description: 'Error message informing the applicant that the provided email address may not contain more than one "@"',
  },
  emailNoName: {
    id: 'sambla.se.emailNoName',
    defaultMessage: 'A valid e-mail needs something before the @',
    description: 'Error message informing the applicant that the email address must contain a name in from of the email address',
  },
  emailDotBeforeAtSign: {
    id: 'sambla.se.emailDotBeforeAtSign',
    defaultMessage: 'The e-mail domain cannot contain a dot (.) before @.',
    description: 'Error message informing the applicant that the domain of the email address can not contain two dots after each other',
  },
  emailTwoDots: {
    id: 'sambla.se.emailTwoDots',
    defaultMessage: 'The email cannot contain two dots in a row.',
    description: 'Error message informing the applicant that the domain of the email address can not contain two dots after each other',
  },
  emailNoDomain: {
    id: 'sambla.se.emailNoDomain',
    defaultMessage: 'A valid e-mail needs to end with .se/.com/.net or alike',
    description: 'Error message informing the applicant that the email address provided must end with a domain, for example ".com", ".se" or ".nu"',
  },
  emailNoTopLevelDomain: {
    id: 'sambla.se.emailNoTopLevelDomain',
    defaultMessage: 'A valid e-mail needs a web address after @',
    description: 'Error message informing the applicant that the email address provided must contain a domain name after the "@"',
  },
  coApplicantEmailRequired: {
    id: 'sambla.se.coApplicantEmailRequired',
    defaultMessage: 'Please enter a valid e-mail',
    description: 'Error message informing the applicant that an email address must be provided',
  },
  employerNamePlaceholder: {
    id: 'sambla.se.employerNamePlaceholder',
    defaultMessage: 'Employer name',
    description: 'Default-text för arbetsgivarnamn i formulär.',
  },
  employerNameLabel: {
    id: 'sambla.se.employerNameLabel',
    defaultMessage: 'Employer',
    description: 'Label-text för företagsnamn i formulär.',
  },
  employerNameTooltip: {
    id: 'sambla.se.employerNameTooltip',
    defaultMessage: 'Please enter the name of the workplace you currently work at. If you have several employers, write the one that you would consider is your main one. Our partners need this information to handle your application.',
    description: 'Tooltip-text för företagsnamn i formulär.',
  },
  employerNameRequired: {
    id: 'sambla.se.employerNameRequired',
    defaultMessage: 'Please enter the name of your employer',
    description: 'Error message informing the applicant that an employer must be provided',
  },
  coApplicantEmployerNamePlaceholder: {
    id: 'sambla.se.coApplicantEmployerNamePlaceholder',
    defaultMessage: 'Employer name',
    description: 'Default text employer name',
  },
  coApplicantEmployerNameLabel: {
    id: 'sambla.se.coApplicantEmployerNameLabel',
    defaultMessage: 'Employer of co-applicant',
    description: 'Label text for employer name',
  },
  coApplicantEmployerNameTooltip: {
    id: 'sambla.se.coApplicantEmployerNameTooltip',
    defaultMessage: "Please enter the name of the workplace your co-applicant's currently work at. If they have several employers, write the one that would be considered to be the main one.",
    description: 'Tooltip-text for coApplicant company name in form.',
  },
  coApplicantEmployerNameRequired: {
    id: 'sambla.se.coApplicantEmployerNameRequired',
    defaultMessage: "Please enter the name of your co-applicant's employer",
    description: 'Error message informing the applicant that an employer must be provided',
  },
  employerPhonePlaceholder: {
    id: 'sambla.se.employerPhonePlaceholder',
    defaultMessage: 'Employer phone number',
    description: 'Default-text för arbetsgivarens telefonnummer i formulär.',
  },
  employerPhoneLabel: {
    id: 'sambla.se.employerPhoneLabel',
    defaultMessage: 'Employer phone number',
    description: 'Label-text för arbetsgivarens telefonnummer i formulär.',
  },
  employerPhoneTooltip: {
    id: 'sambla.se.employerPhoneTooltip',
    defaultMessage: 'Your employer telephone number can make it easier for our partners if they need additional information when considering giving out a loan proposal',
    description: 'Tooltip-text för arbetsgivarens telefonnummer i formulär.',
  },
  employerPhoneRequired: {
    id: 'sambla.se.employerPhoneRequired',
    defaultMessage: "Please enter your employer's phone number",
    description: 'Error message for when the customer hasnt filled in a phone number to their employer',
  },
  coApplicantEmployerPhoneRequired: {
    id: 'sambla.se.coApplicantEmployerPhoneRequired',
    defaultMessage: "Please enter their employer's phone number",
    description: 'Error message for when the customer hasnt filled in a phone number to their co applicants employer',
  },
  employerPhoneWrongNumber: {
    id: 'sambla.se.employerPhoneWrongNumber',
    defaultMessage: 'The phone number appears to be incorrect. Please enter a valid number',
    description: 'Error message for when the customer fills in a non-valid input for the number to their employer.',
  },
  employerPhoneWrongBeginning: {
    id: 'sambla.se.employerPhoneWrongBeginning',
    defaultMessage: 'The number needs to start with 0, +46 or 0046.',
    description: 'Error message for when the customer fills in a foreign phone number to their employer.',
  },
  employerPhoneTooShort: {
    id: 'sambla.se.employerPhoneTooShort',
    defaultMessage: "The phone number is too short. Verify that you haven't forgotten a digit",
    description: 'Error message for when the customer fills in a too short phone number to their employer.',
  },
  employerPhoneTooLong: {
    id: 'sambla.se.employerPhoneTooLong',
    defaultMessage: "The phone number is too long. Verify that you haven't added a digit too many",
    description: 'Error message for when the customer fills in a too long phone number to their employer.',
  },
  coApplicantEmployerPhonePlaceholder: {
    id: 'sambla.se.coApplicantEmployerPhonePlaceholder',
    defaultMessage: 'Employer phone number',
    description: 'placeholder text for coApplicant employer phone number in form.',
  },
  coApplicantEmployerPhoneLabel: {
    id: 'sambla.se.coApplicantEmployerPhoneLabel',
    defaultMessage: 'Employer phone of co-applicant',
    description: 'label text for coApplicant employer phone number in form.',
  },
  coApplicantEmployerPhoneTooltip: {
    id: 'sambla.se.coApplicantEmployerPhoneTooltip',
    defaultMessage: "Your co-applicant's employer telephone number can make it easier for our partners if they need additional information when considering giving out a loan proposal.",
    description: 'Tooltip-text for coApplicant employer phone number in form.',
  },
  employmentTypeLabel: {
    id: 'sambla.se.employmentTypeLabel',
    defaultMessage: 'Employment',
    description: 'Label-text för sysselsättnig i formulär.',
  },
  employmentTypeTooltip: {
    id: 'sambla.se.employmentTypeTooltip',
    defaultMessage: 'Please select your employment type. If you are on leave, choose the employment option from which you got your leave. If you have several employments, please select the one that you consider is your main one. The choice "Pensioner" also includes early retirees.',
    description: 'Tooltip-text för sysselsättnig i formulär.',
  },
  employmentTypePermanent: {
    id: 'sambla.se.employmentTypePermanent',
    defaultMessage: 'Permanent',
    description: 'Employment type - Tenured/employed indefinitely/untill further notice',
  },
  employmentTypeProbationary: {
    id: 'sambla.se.employmentTypeProbationary',
    defaultMessage: 'Trial period',
    description: 'Employment type - Trial period',
  },
  employmentTypeRetired: {
    id: 'sambla.se.employmentTypeRetired',
    defaultMessage: 'Pensioner',
    description: 'Employment type - Retired/early retirement',
  },
  employmentTypeDisabilityPension: {
    id: 'sambla.se.employmentTypeDisabilityPension',
    defaultMessage: 'Disability pension',
    description: 'Employment type - Retired/pensioner on disibility',
  },
  employmentTypeProject: {
    id: 'sambla.se.employmentTypeProject',
    defaultMessage: 'Fixed-term',
    description: 'Employment type - Temporary employment/employed during project',
  },
  employmentTypeByTheHour: {
    id: 'sambla.se.employmentTypeByTheHour',
    defaultMessage: 'Hourly wage',
    description: 'Employment type - Extra work/hourly contract',
  },
  employmentTypeEmployedAtOwnCompany: {
    id: 'sambla.se.employmentTypeEmployedAtOwnCompany',
    defaultMessage: 'Own business',
    description: 'Employment type - Has his/her own business',
  },
  coApplicantEmploymentTypeLabel: {
    id: 'sambla.se.coApplicantEmploymentTypeLabel',
    defaultMessage: 'Employment of co-applicant',
    description: 'Label-text för sysselsättnig i formulär.',
  },
  coApplicantEmploymentTypeTooltip: {
    id: 'sambla.se.coApplicantEmploymentTypeTooltip',
    defaultMessage: "Please select your co-applicant's employment type. If they are on leave, choose the employment option from which they got their leave. If they have several employments, please select the one that is considered the main one. The choice 'Pensioner' also includes early retirees.",
    description: 'Tooltip-text for coApplicant employment in form.',
  },
  employmentSinceMonthTooltip: {
    id: 'sambla.se.employmentSinceMonthTooltip',
    defaultMessage: 'Enter approximately when you were employed by the company you work for today.',
    description: 'Tooltip for "employment since"-input',
  },
  employmentSinceMonthPlaceholder: {
    id: 'sambla.se.employmentSinceMonthPlaceholder',
    defaultMessage: 'Month',
    description: 'Default text for "employment since"-input in months, placed inside form',
  },
  employmentSinceYearPlaceholder: {
    id: 'sambla.se.employmentSinceYearPlaceholder',
    defaultMessage: 'Year',
    description: 'Default text for "employment since"-input in years, placed inside form',
  },
  employmentSinceYearLabel: {
    id: 'sambla.se.employmentSinceYearLabel',
    defaultMessage: 'Employed since (year)',
    description: 'Label for "employment since"-input in years, placed inside form',
  },
  employmentSinceMonthLabel: {
    id: 'sambla.se.employmentSinceMonthLabel',
    defaultMessage: 'Employed since (month)',
    description: 'Label for "employment since"-input in months, placed inside form',
  },
  employmentSinceYearTooltip: {
    id: 'sambla.se.employmentSinceYearTooltip',
    defaultMessage: 'Enter approximately how long you have been employed by the company you work for today.',
    description: 'Tooltip for "employment since"-input',
  },
  employedSinceMonthPlaceholder: {
    id: 'sambla.se.employedSinceMonthPlaceholder',
    defaultMessage: 'Month',
    description: 'Default text for "employment since"-input in months, placed inside form',
  },
  retiredEmploymentSinceLabel: {
    id: 'sambla.se.retiredEmploymentSinceLabel',
    defaultMessage: 'Retired since',
    description: '',
  },
  retiredEmploymentSinceTooltip: {
    id: 'sambla.se.retiredEmploymentSinceTooltip',
    defaultMessage: 'Enter approximately when you retired.',
    description: '',
  },
  coApplicantRetiredEmploymentSinceTooltip: {
    id: 'sambla.se.coApplicantRetiredEmploymentSinceTooltip',
    defaultMessage: 'Enter approximately when your co-applicant retired.',
    description: 'Tooltip-text for coApplicant retired since in form.',
  },
  employedSinceRequiredYear: {
    id: 'sambla.se.employedSinceRequiredYear',
    defaultMessage: 'Please enter the year you started your current employment',
    description: 'Error message informing the applicant that they must pass in which year their employment started',
  },
  coAppEmployedSinceRequiredYear: {
    id: 'sambla.se.coApplicant.employedSinceRequiredYear',
    defaultMessage: 'Please enter the year your co-applicant started at their current employer',
    description: 'Error message informing the co applicant that they must pass in which year their employment started',
  },
  employedSinceRequiredMonth: {
    id: 'sambla.se.employedSinceRequiredMonth',
    defaultMessage: 'Please enter the month you started your current employment',
    description: 'Error message informing the applicant that they must pass in which month their employment started',
  },
  coAppEmployedSinceRequiredMonth: {
    id: 'sambla.se.coApplicant.employedSinceRequiredMonth',
    defaultMessage: 'Please enter the month your co-applicant started at their current employer',
    description: 'Error message informing the co applicant that they must pass in which month their employment started',
  },
  employedSinceOnlyNumbers: {
    id: 'sambla.se.employedSinceOnlyNumbers',
    defaultMessage: 'Please enter the year in digits (e.g. 2010).',
    description: 'Error message informing the applicant that they may only use digits when filling in which year their employment started',
  },
  employedSinceFourDigits: {
    id: 'sambla.se.employedSinceFourDigits',
    defaultMessage: 'Please write the year in 4 digits, e.g. 2010.',
    description: 'Error message informing the applicant that the format for "year their employment started" should be filled in with four digits',
  },
  employedSinceTooFarBack: {
    id: 'sambla.se.employedSinceTooFarBack',
    defaultMessage: 'Enter the year you started (e.g. 2010).',
    description: 'Error message informing the applicant that the year their employment started is set to far back in time',
  },
  coApplicantEmployedSinceTooFarBack: {
    id: 'sambla.se.coApplicant.employedSinceTooFarBack',
    defaultMessage: 'Enter the year they started (e.g. 2010).',
    description: 'Error message informing the co applicant started their employment to far back in time',
  },
  employedSinceStartTimeInTheFuture: {
    id: 'sambla.se.employedSinceStartTimeInTheFuture',
    defaultMessage: 'Your employment date cannot be in the future',
    description: 'Error message informing the applicant that their "start time of the employment" cannot be set to a date in the future',
  },
  coAppEmployedSinceStartTimeInTheFuture: {
    id: 'sambla.se.employedSinceCoApplicant.startTimeInTheFuture',
    defaultMessage: "Their employment date can't be in the future.",
    description: 'Error message informing the co applicant that their "start time of the employment" cannot be set to a date in the future',
  },
  employedSinceStartTimeInTheFutureMonth: {
    id: 'sambla.se.employedSinceStartTimeInTheFutureMonth',
    defaultMessage: "The employment date can't be in the future",
    description: 'Error message informing the start date is in the future',
  },
  homeCostPlaceholder: {
    id: 'sambla.se.homeCostPlaceholder',
    defaultMessage: 'E.g. 5 000',
    description: 'Default-text för boendekostnad i formulär.',
  },
  homeCostLabel: {
    id: 'sambla.se.homeCostLabel',
    defaultMessage: 'Housing cost (SEK/mth)',
    description: 'Label-text för boendekostnad i formulär.',
  },
  homeCostTooltip: {
    id: 'sambla.se.homeCostTooltip',
    defaultMessage: 'Please enter the monthly cost connected to your housing. This can consist of rent/fee, heat, water, electricity, insurance etc. If you are several who split the cost, enter your share in (e.g. half the total cost).',
    description: 'Tooltip-text för boendekostnad i formulär.',
  },
  currentHomeCostLabel: {
    id: 'sambla.se.mortgage.currentHomeCostLabel',
    defaultMessage: 'Current housing cost',
    description: 'Label text for current housing cost in form',
  },
  currentHomeCostTooltip: {
    id: 'sambla.se.mortgage.currentHomeCostTooltip',
    defaultMessage: 'Please enter the monthly cost connected to your housing. This can consist of rent/fee, heat, water, electricity, insurance etc. If you are several who split the cost, only enter your share (e.g. half the total cost).',
    description: 'Tooltip text for current housing cost in form',
  },
  homeCostRequired: {
    id: 'sambla.se.homeCostRequired',
    defaultMessage: 'Please enter your monthly housing cost',
    description: 'Inform the customer that they must pass in a monthly cost for their current home-situation (ex: rent)',
  },
  homeCostOnlyDigits: {
    id: 'sambla.se.homeCostOnlyDigits',
    defaultMessage: 'Please write your housing cost in numbers only',
    description: 'Inform the customer that they may only pass in digits in the input for the monthly cost of their home-situation',
  },
  homeCostTooHigh: {
    id: 'sambla.se.homeCostTooHigh',
    defaultMessage: 'Your housing cost cannot be more than {maxCost, number} SEK/mth for you to apply',
    description: 'Inform the customer that we may unfortunatly only accept a monthly cost of "x" and that their cost is to high',
  },
  homeCostTooLow: {
    id: 'sambla.se.homeCostTooLow',
    defaultMessage: 'Enter monthly cost for your accommodation.',
    description: 'Inform the customer that their passed in monthly cost for their home-situation is set to low, for example "0"',
  },
  coApplicantHomeCostPlaceholder: {
    id: 'sambla.se.coApplicantHomeCostPlaceholder',
    defaultMessage: 'E.g. 5 000',
    description: 'Default-text för boendekostnad i formulär.',
  },
  coApplicantHomeCostLabel: {
    id: 'sambla.se.coApplicantHomeCostLabel',
    defaultMessage: 'Housing cost (SEK/mth)',
    description: 'Label-text för boendekostnad i formulär.',
  },
  coApplicantHomeCostTooltip: {
    id: 'sambla.se.coApplicantHomeCostTooltip',
    defaultMessage: "Please enter the monthly cost connected to your co-applicant's housing. This can consist of rent/fee, heat, water, electricity, insurance etc. If there are several who split the cost, enter your co-applicant's share in (e.g. half the total cost).",
    description: 'Tooltip-text for coApplicant home cost in form.',
  },
  coApplicantHomeCostRequired: {
    id: 'sambla.se.coApplicantHomeCostRequired',
    defaultMessage: "Please enter your co-applicant's monthly housing cost",
    description: 'Inform the customer that they must pass in a monthly cost for their current home-situation (ex: rent)',
  },
  coApplicantHomeCostOnlyDigits: {
    id: 'sambla.se.coApplicantHomeCostOnlyDigits',
    defaultMessage: 'Please write the housing cost in numbers only',
    description: 'Inform the customer that they may only pass in digits in the input for the monthly cost of their home-situation',
  },
  coApplicantHomeCostTooHigh: {
    id: 'sambla.se.coApplicantHomeCostTooHigh',
    defaultMessage: 'Their housing cost cannot be more than {maxCost, number} SEK/mth for you to apply',
    description: 'Inform the customer that we may unfortunatly only accept a monthly cost of "x" and that their cost is to high',
  },
  coApplicantHomeCostTooLow: {
    id: 'sambla.se.coApplicantHomeCostTooLow',
    defaultMessage: 'Enter monthly cost for their accommodation.',
    description: 'Inform the customer that their passed in monthly cost for their home-situation is set to low, for example "0"',
  },
  homeTypeLabel: {
    id: 'sambla.se.homeTypeLabel',
    defaultMessage: 'Housing',
    description: 'Label-text för boendeform i formulär.',
  },
  homeTypeTooltip: {
    id: 'sambla.se.homeTypeTooltip',
    defaultMessage: 'If you have several addresses, please choose the one where you live today. "Own apartment" is considered bought apartments in housing cooperatives.',
    description: 'Tooltip-text för boendeform i formulär.',
  },
  currentHomeTypeLabel: {
    id: 'sambla.se.mortgage.currentHomeTypeLabel',
    defaultMessage: 'Current housing',
    description: 'Label text for current housing in form',
  },
  currentHomeTypeTooltip: {
    id: 'sambla.se.mortgage.currentHomeTypeTooltip',
    defaultMessage: 'If you have several addresses, please choose the one where you live today. "Own apartment" is considered bought apartments in housing cooperatives.',
    description: 'Tooltip text for current housing in form',
  },
  homeTypeRentingApartment: {
    id: 'sambla.se.homeTypeRentingApartment',
    defaultMessage: 'Rented apartment',
    description: 'Residential type - Renting appartment',
  },
  newPurposeHeaderText: {
    id: 'sambla.se.newPurposeHeaderText',
    defaultMessage: 'Loan purpose for the new loan part',
    description: 'Header in the application form for purpose of the part of the loan that is considered new loan',
  },
  homeTypeHouseOrTownhouse: {
    id: 'sambla.se.homeTypeHouseOrTownhouse',
    defaultMessage: 'House',
    description: 'Residential type - House',
  },
  homeTypeCondominium: {
    id: 'sambla.se.homeTypeCondominium',
    defaultMessage: 'Own apartment',
    description: 'Residential type - Condominium',
  },
  homeTypeRentingRoom: {
    id: 'sambla.se.homeTypeRentingRoom',
    defaultMessage: 'Live-in',
    description: 'Residential type - Renting room/lodger',
  },
  coApplicantHomeTypeLabel: {
    id: 'sambla.se.coApplicantHomeTypeLabel',
    defaultMessage: 'Housing of co-applicant',
    description: 'Label-text för boendeform i formulär.',
  },
  coApplicantHomeTypeTooltip: {
    id: 'sambla.se.coApplicantHomeTypeTooltip',
    defaultMessage: 'If your co-applicant have several addresses, please choose the one where they live today. "Own apartment" is considered bought apartments in housing cooperatives.',
    description: 'Tooltip-text for coApplicant home type in form.',
  },
  loanPurposeTooltip: {
    id: 'sambla.se.loanPurposeTooltip',
    defaultMessage: 'What are you mainly going to use the money for?',
    description: 'Tooltip-text för lånets syfte i formulär.',
  },
  loanPurposeRequired: {
    id: 'sambla.se.loanPurposeRequired',
    defaultMessage: 'The loan purpose is required.',
    description: '',
  },
  loanPurposeConsolidateLoans: {
    id: 'sambla.se.loanPurposeConsolidateLoans',
    defaultMessage: 'Consolidate loans',
    description: 'Purpose of the loan - Consolidate loans',
  },
  loanPurposeVehicles: {
    id: 'sambla.se.loanPurposeVehicles',
    defaultMessage: 'Vehicle',
    description: 'Purpose of the loan - Vehichle expenses',
  },
  loanPurposeConsumption: {
    id: 'sambla.se.loanPurposeConsumption',
    defaultMessage: 'Consumption',
    description: 'Purpose of the loan - Shopping/consumption',
  },
  loanPurposeHealth: {
    id: 'sambla.se.loanPurposeHealth',
    defaultMessage: 'Health/medical',
    description: 'Purpose of the loan - Health',
  },
  loanPurposeRenovation: {
    id: 'sambla.se.loanPurposeRenovation',
    defaultMessage: 'Renovation',
    description: 'Purpose of the loan - Renovation',
  },
  loanPurposeServices: {
    id: 'sambla.se.loanPurposeServices',
    defaultMessage: 'Services',
    description: 'Purpose of the loan - Services(for example "wedding")',
  },
  loanPurposeBuffer: {
    id: 'sambla.se.loanPurposeBuffer',
    defaultMessage: 'Investment',
    description: 'Purpose of the loan - Buffer/Investment',
  },
  loanPurposeStudies: {
    id: 'sambla.se.loanPurposeStudies',
    defaultMessage: 'Studies',
    description: 'Purpose of the loan - Studies',
  },
  mortgageLoanPurposeRefinance: {
    id: 'sambla.se.mortgage.loanPurposeRefinance',
    defaultMessage: 'Refinance',
    description: 'Purpose of the loan - Refinance',
  },
  mortgageLoanPurposePropertyPurchase: {
    id: 'sambla.se.mortgage.loanPurposePropertyPurchase',
    defaultMessage: 'New purchase',
    description: 'Purpose of the loan - Property Purchase',
  },
  mortgagePropertyTypeCondo: {
    id: 'sambla.se.mortgage.propertyTypeCondo',
    defaultMessage: 'Condo',
    description: 'Form button for property type Condo',
  },
  mortgagePropertyTypeVilla: {
    id: 'sambla.se.mortgage.propertyTypeVilla',
    defaultMessage: 'Villa',
    description: 'Form button for property type Villa',
  },
  mortgagePropertyTypeCottage: {
    id: 'sambla.se.mortgage.propertyTypeCottage',
    defaultMessage: 'Cottage',
    description: 'Form button for property type Cottage',
  },
  mergeLoanYes: {
    id: 'sambla.se.mergeLoanYes',
    defaultMessage: 'Yes',
    description: '',
  },
  mergeLoanNo: {
    id: 'sambla.se.mergeLoanNo',
    defaultMessage: 'No',
    description: '',
  },
  monthlySalaryPlaceholder: {
    id: 'sambla.se.monthlySalaryPlaceholder',
    defaultMessage: 'E.g. 25 000',
    description: 'Default-text för inkomst i formulär.',
  },
  monthlySalaryLabel: {
    id: 'sambla.se.monthlySalaryLabel',
    defaultMessage: 'Total monthly net income (SEK/mth)',
    description: 'Label-text för inkomst i formulär.',
  },
  monthlySalaryTooltip: {
    id: 'sambla.se.monthlySalaryTooltip',
    defaultMessage: 'Enter your approximate total monthly income before taxes from all income sources. This can consist of salary, comission, rental income and pension, to name a few. Note that you need to prove your stated income through payment slips and alike.',
    description: 'Tooltip-text för inkomst i formulär.',
  },
  coApplicantMonthlySalaryPlaceholder: {
    id: 'sambla.se.coApplicantMonthlySalaryPlaceholder',
    defaultMessage: 'E.g. 25 000',
    description: 'Default-text för inkomst i formulär.',
  },
  coApplicantMonthlySalaryLabel: {
    id: 'sambla.se.coApplicantMonthlySalaryLabel',
    defaultMessage: 'Net income (SEK/mth)',
    description: 'Label-text för inkomst i formulär.',
  },
  coApplicantMonthlySalaryTooltip: {
    id: 'sambla.se.coApplicantMonthlySalaryTooltip',
    defaultMessage: "Enter your co-applicant's approximate total monthly income before taxes. This can consist of salary, commission, rental income and pension, to name a few. Note that your co-applicant may need to prove their stated income through payment slips and alike.",
    description: 'Tooltip-text for coApplicant income in form.',
  },
  monthlySalaryRequired: {
    id: 'sambla.se.monthlySalaryRequired',
    defaultMessage: 'Please enter your monthly net income (SEK/mth)',
    description: 'Error message informing the applicant that a monthly salary must be provided',
  },
  monthlySalaryOnlynumbers: {
    id: 'sambla.se.monthlySalaryOnlynumbers',
    defaultMessage: 'Please write your income in numbers only',
    description: 'Error message informing the customer that only numbers are allowed to be passed in',
  },
  monthlySalaryTooLow: {
    id: 'sambla.se.monthlySalaryTooLow',
    defaultMessage: 'Your net income must be at least {minSalary, number} SEK/mth in order to apply',
    description: 'Error message informing the customer that the monthly salary provided is to low and information that you need to have a monthly salary of at least x in order to compare loans',
  },
  monthlySalaryTooHigh: {
    id: 'sambla.se.monthlySalaryTooHigh',
    defaultMessage: 'Your net income must not be more than {maxSalary, number} SEK/mth for you to apply',
    description: 'Error message informing the customer that the monthly salary provided is to high',
  },
  coApplicantMonthlySalaryRequired: {
    id: 'sambla.se.coApplicantMonthlySalaryRequired',
    defaultMessage: "Please enter your co-applicant's monthly net income (SEK/mth)",
    description: 'Error message informing the applicant that a monthly salary must be provided',
  },
  coApplicantMonthlySalaryOnlynumbers: {
    id: 'sambla.se.coApplicantMonthlySalaryOnlynumbers',
    defaultMessage: "Please enter your co-applicant's income in numbers only",
    description: 'Error message informing the customer that only numbers are allowed to be passed in',
  },
  coApplicantMonthlySalaryTooLow: {
    id: 'sambla.se.coApplicantMonthlySalaryTooLow',
    defaultMessage: 'Their net income must be at least {minSalary, number} SEK/mth in order to apply',
    description: 'Error message informing the customer that the monthly salary provided is to low and information that you need to have a monthly salary of at least x in order to compare loans',
  },
  coApplicantMonthlySalaryTooHigh: {
    id: 'sambla.se.coApplicantMonthlySalaryTooHigh',
    defaultMessage: 'Their net income must not be more than {maxSalary, number} SEK/mth for you to apply',
    description: 'Error message informing the customer that the monthly salary provided is to high',
  },
  nationalIdPlaceholder: {
    id: 'sambla.se.nationalIdPlaceholder',
    defaultMessage: 'YYMMDDXXXX',
    description: 'Default-text för personnummer i formulär.',
  },
  nationalIdLabel: {
    id: 'sambla.se.nationalIdLabel',
    defaultMessage: 'National ID',
    description: 'Label-text för personnummer i formulär.',
  },
  nationalIdTooltip: {
    id: 'sambla.se.nationalIdTooltip',
    defaultMessage: 'The application is personal and your swedish national ID is needed to verify your identity. The information is secure with us and processed securely according to GDPR.',
    description: 'Tooltip-text för personnummer i formulär.',
  },
  nationalIdMustBeUnique: {
    id: 'sambla.se.nationalIdMustBeUnique',
    defaultMessage: 'The main applicant and the co-applicant cannot be the same person.',
    description: 'Informing the customer that the applicant and co-applicant can not be the same person/use the same social security number',
  },
  nationalIdRequired: {
    id: 'sambla.se.nationalIdRequired',
    defaultMessage: 'Please enter your Swedish ID number',
    description: 'Error message for when a social security number must be provided',
  },
  nationalIdWrongFormat: {
    id: 'sambla.se.nationalIdWrongFormat',
    defaultMessage: 'Please write in the correct format, 10 or 12 digits, e.g. 801122-0123',
    description: 'Error message when the social security number is in the wrong format. Provides an example of a correct formated social security number',
  },
  nationalIdWrongDate: {
    id: 'sambla.se.nationalIdWrongDate',
    defaultMessage: 'Your birth date {dateOfBirthString} is not valid. Please make sure there are no mistakes',
    description: 'Error message when providing birth date incorrectly',
  },
  nationalIdDateInTheFuture: {
    id: 'sambla.se.nationalIdDateInTheFuture',
    defaultMessage: "{dateOfBirthString} is a future date, and can't be used",
    description: 'Error message when choosing date of birth as a date in the future',
  },
  nationalIdTooYoung: {
    id: 'sambla.se.nationalIdTooYoung',
    defaultMessage: 'You have to be at least {minAge} years of age to submit an application.',
    description: 'Error message when providing a date of birth making you younger than the minimum age allowed',
  },
  nationalIdTooOld: {
    id: 'sambla.se.nationalIdTooOld',
    defaultMessage: 'Unfortunately, we can only help people up to {maxAge} years of age.',
    description: 'Error message when providing a date of birth making you older than the maximum age allowed',
  },
  nationalIdInvalid: {
    id: 'sambla.se.nationalIdInvalid',
    defaultMessage: 'Your 4 last digits do not seem to match your birth date. Please verify them',
    description: 'Error message when providing a social security number wrongly formated. Asks the customer to review their last four digits in their social security number',
  },
  coApplicantNationalIdPlaceholder: {
    id: 'sambla.se.coApplicantNationalIdPlaceholder',
    defaultMessage: 'YYMMDDXXXX',
    description: 'Default-text för personnummer i formulär.',
  },
  coApplicantNationalIdLabel: {
    id: 'sambla.se.coApplicantNationalIdLabel',
    defaultMessage: 'National ID of co-applicant',
    description: 'Label-text för personnummer i formulär.',
  },
  coApplicantNationalIdTooltip: {
    id: 'sambla.se.coApplicantNationalIdTooltip',
    defaultMessage: 'Since the application is personal, we need to verify your co-applicant national ID (dont write your own). This needs to be a swedish social security number',
    description: 'Tooltip-text for coApplicant in form.',
  },
  coApplicantNationalIdrequired: {
    id: 'sambla.se.coApplicantNationalIdrequired',
    defaultMessage: "Please enter your co-applicant's Swedish ID number",
    description: 'Error message for when a social security number must be provided',
  },
  coApplicantNationalIdWrongFormat: {
    id: 'sambla.se.coApplicantNationalIdWrongFormat',
    defaultMessage: 'Please write in the correct format, 10 or 12 digits, e.g. 801122-0123',
    description: 'Error message when the social security number is in the wrong format. Provides an example of a correct formated social security number',
  },
  coApplicantNationalIdWrongDate: {
    id: 'sambla.se.coApplicantNationalIdWrongDate',
    defaultMessage: 'Their birth date {dateOfBirthString} is not valid, please make sure your input is correct',
    description: 'Error message when providing birth date incorrectly',
  },
  coApplicantNationalIdDateInTheFuture: {
    id: 'sambla.se.coApplicantNationalIdDateInTheFuture',
    defaultMessage: "{dateOfBirthString} is a future date, and can't be used",
    description: 'Error message when choosing date of birth as a date in the future',
  },
  coApplicantNationalIdInvalid: {
    id: 'sambla.se.coApplicantNationalIdInvalid',
    defaultMessage: 'Their 4 last digits seems to not match the birth date. Please verify them',
    description: 'Error message when providing a social security number wrongly formated. Asks the customer to review their last four digits in their social security number',
  },
  phoneNumberPlaceholder: {
    id: 'sambla.se.phoneNumberPlaceholder',
    defaultMessage: '07__ __ __ __',
    description: 'Default-text för mobilnummer i formulär.',
  },
  phoneNumberLabel: {
    id: 'sambla.se.phoneNumberLabel',
    defaultMessage: 'Phone number',
    description: '',
  },
  phoneNumberTooltip: {
    id: 'sambla.se.phoneNumberTooltip',
    defaultMessage: 'Your telephone number is required so we can give you information about your application. If a bid is accepted, the banks will also communicate with you using this telephone number.',
    description: 'Tooltip-text för mobilnummer i formulär.',
  },
  phoneNumberMustBeUnique: {
    id: 'sambla.se.phoneNumberMustBeUnique',
    defaultMessage: 'The main applicant and the co-applicant cannot have the same phone number.',
    description: 'Informing the customer/applicant that their co-applicant may not have an identical phone number as theirs',
  },
  phoneNumberWrongBeginning: {
    id: 'sambla.se.phoneNumberWrongBeginning',
    defaultMessage: 'We only accept cell phone numbers. The number needs to start with 07, +46 or 0046',
  },
  phoneNumberFormatError: {
    id: 'sambla.se.phoneNumberFormatError',
    defaultMessage: 'Must begin with 0 or Swedish country code (+46).',
    description: '',
  },
  phoneNumberPrefixError: {
    id: 'sambla.se.phoneNumberPrefixError',
    defaultMessage: 'Your phone number must begin with 04, 0457 or 050.',
    description: '',
  },
  phoneNumberWrongNumber: {
    id: 'sambla.se.phoneNumberWrongNumber',
    defaultMessage:
      'The phone number appears to be incorrect. Please enter a valid Swedish mobile phone number.',
  },
  phoneNumberGeneralError: {
    id: 'sambla.se.phoneNumberGeneralError',
    defaultMessage: 'The phone number appears to be incorrect. Please enter a valid Swedish mobile phone number.',
  },
  phoneNumberTooShort: {
    id: 'sambla.se.phoneNumberTooShort',
    defaultMessage: "The phone number is too short. Verify that you haven't forgotten any digits",
    description: 'Error message for when the customer fills in a too short phone number.',
  },
  phoneNumberTooLong: {
    id: 'sambla.se.phoneNumberTooLong',
    defaultMessage: "The phone number is too long. Verify that you haven't added a digit too many",
    description: 'Error message for when the customer fills in a too long phone number.',
  },
  coApplicantPhoneNumberTooltip: {
    id: 'sambla.se.coApplicantPhoneNumberTooltip',
    defaultMessage: "Your co-applicant's phone number is needed for them to be able to take part of the loan offers. When you have accepted a loan offer, banks might also need to come in contact with your co-applicant.",
    description: 'Tooltip-text for coApplicant mobile number in form.',
  },
  coApplicantPhoneNumberPlaceholder: {
    id: 'sambla.se.coApplicantPhoneNumberPlaceholder',
    defaultMessage: '07__ __ __ __',
    description: 'Default-text för coApplicant mobilnummer i formulär.',
  },
  coApplicantPhoneNumberLabel: {
    id: 'sambla.se.coApplicantPhoneNumberLabel',
    defaultMessage: 'Phone number of co-applicant',
    description: 'label for coApplicant mobile number in form.',
  },
  coApplicantPhoneNumberRequired: {
    id: 'sambla.se.coApplicantPhoneNumberRequired',
    defaultMessage: "Please enter your co-applicant's phone number",
  },
  coApplicantEmploymentSinceYearPlaceholder: {
    id: 'sambla.se.coApplicantEmploymentSinceYearPlaceholder',
    defaultMessage: 'e.g 2002',
    description: 'Default text for "employment since"-input in years, placed inside form',
  },
  coApplicantEmploymentSinceYearLabel: {
    id: 'sambla.se.coApplicantEmploymentSinceYearLabel',
    defaultMessage: 'Employed since (year)',
    description: 'Label for "employment since"-input in years, placed inside form',
  },
  coApplicantEmploymentSinceMonthLabel: {
    id: 'sambla.se.coApplicantEmploymentSinceMonthLabel',
    defaultMessage: 'Employed since (month)',
    description: 'Label for "employment since"-input in months, placed inside form',
  },
  coApplicantEmploymentSinceYearTooltip: {
    id: 'sambla.se.coApplicantEmploymentSinceYearTooltip',
    defaultMessage: 'Please enter approximately the year when your co-applicant started at their current company. Use 4 numbers, e.g "2002".',
    description: 'Tooltip for "employment since"-input',
  },
  coApplicantEmploymentSinceMonthTooltip: {
    id: 'sambla.se.coApplicantEmploymentSinceMonthTooltip',
    defaultMessage: "Please select approximately the month that your co-applicant started at their current company. If you don't know exactly, pick what you think it is.",
    description: 'Tooltip for "employment since"-input',
  },
  coApplicantEmploymentSinceMonthPlaceholder: {
    id: 'sambla.se.coApplicantEmploymentSinceMonthPlaceholder',
    defaultMessage: 'Month',
    description: 'Default text for "employment since"-input in months, placed inside form',
  },
  betterTermsText: {
    id: 'sambla.se.betterTermsText',
    defaultMessage: 'Having a co-applicant often results in better conditions.',
    description: '',
  },
  submitErrorGeneral: {
    id: 'sambla.se.submitErrorGeneral',
    defaultMessage: 'Your application could not be submitted. Customer service is more than happy to help you through the process. Call: <a>{phoneNumber}</a> or click the button below and we will call you as soon as we can on <i>{customerPhoneNumber}</i>',
    description: 'Default-text för back-end fel i formulär.',
  },
  submitErrorUncaught: {
    id: 'sambla.se.submitErrorUncaught',
    defaultMessage: 'For the moment we have technical issues and your application could not be submitted. Please contact customer service for more help',
    description: 'Default-text for when a customer tries to submit their application but there was an error on the client side.',
  },
  validationErrorGeneral: {
    id: 'sambla.se.validationErrorGeneral',
    defaultMessage: 'Some of the fields are not filled in correctly. Please correct these and try to submit again.',
    description: 'Default-text för fel i fält.',
  },
  validationErrorField: {
    id: 'sambla.se.validationErrorField',
    defaultMessage: 'This field is not filled in correctly. Please correct it and try submitting again.',
    description: 'Default-text för fel i fält.',
  },
  fieldAlreadyInUseEmail: {
    id: 'sambla.se.fieldAlreadyInUseEmail',
    defaultMessage: 'This email address already belongs to an application.',
    description: 'Field error message informing the customer that the given e-mail is already in use in another application',
  },
  fieldAlreadyInUseNationalId: {
    id: 'sambla.se.fieldAlreadyInUseNationalId',
    defaultMessage: 'You have an ongoing application, to access it — click "Log in" in the menu. To make changes to your application, please call customer service on: <span>{phoneNumber}</span>',
    description: 'Default-text för back-end fel i formulär.',
  },
  formSubmit: {
    id: 'sambla.se.formSubmit',
    defaultMessage: 'Apply now - for free',
    description: 'Label for submit button',
  },
  monthlyCostApproximateMonthlyCostLabel: {
    id: 'sambla.se.monthlyCostApproximateMonthlyCostLabel',
    defaultMessage: 'Monthly cost approx. *',
    description: 'The label for the apprax. monthly cost',
  },
  monthlyCostApproximateCostLabel: {
    id: 'sambla.se.monthlyCostApproximateCostLabel',
    defaultMessage: 'Approximate cost',
    description: 'approx cost',
  },
  monthlyCostApproximateCostPerMonthDesktop: {
    id: 'sambla.se.monthlyCostApproximateCostPerMonthDesktop',
    defaultMessage: '{monthlyCost} / month *',
    description: 'Shows the apprx. monthly cost on desktop',
  },
  monthlyCostApproximateCostPerMonthMobile: {
    id: 'sambla.se.monthlyCostApproximateCostPerMonthMobile',
    defaultMessage: '<strong>{monthlyCost}</strong>/mth*',
    description: 'Shows the apprx. monthly cost on mobile',
  },
  monthlyCostApproximateCostLabelYears: {
    id: 'sambla.se.monthlyCostApproximateCostLabelYears',
    defaultMessage: 'in <strong> {repaymentTime} years </strong>',
    description: 'Repayment time in years',
  },
  yearlyCostApproximateCostLabelYears: {
    id: 'sambla.se.yearlyCostApproximateCostLabelYears',
    defaultMessage: '<strong> {repaymentTime} years </strong>',
    description: 'Repayment time in years',
  },
  yearlyCostApproximateCostPerMonthMobile: {
    id: 'sambla.se.yearlyCostApproximateCostPerMonthMobile',
    defaultMessage: '<strong> {monthlyCost}</strong>/mth*',
    description: 'Shows the apprx. monthly cost on mobile',
  },
  combinedLoanConsolidationAmount: {
    id: 'sambla.se.combinedLoanConsolidationAmount',
    defaultMessage: 'Loans to consolidate: <strong>{consolidationAmount}</strong>',
    description: 'Part of the loan that is consolidation amount',
  },
  samblaPlusText: {
    id: 'sambla.se.samblaPlusText',
    defaultMessage: 'I want to become a member of Sambla Plus to receive newsletters and offers via SMS and e-mail. ',
    description: 'Text besides sambla plus checkbox in form',
  },
  samblaPlusReadMore: {
    id: 'sambla.se.samblaPlusReadMore',
    defaultMessage: 'Read more',
    description: 'Text for link to read about Sambla Plus',
  },
  repaymentTimeWarning: {
    id: 'sambla.se.repaymentTimeWarning',
    defaultMessage: 'Most lenders offer a maximum repayment time of 15 years. These banks offer a maximum of 15 years, even if you apply for longer than that.',
    description: 'warning when entering over 15 years repayment time',
  },
  loanCalculatorTotalLoanAmountLabel: {
    id: 'sambla.se.loanCalculator.totalLoanAmountLabel',
    defaultMessage: 'Loan amount',
    description: 'Label-text för totalbelopp av ett nuvarande kreditlån i formulär.',
  },
  loanCalculatorTotalLoanAmountPlaceholder: {
    id: 'sambla.se.loanCalculator.totalLoanAmountPlaceholder',
    defaultMessage: 'XX XXX',
    description: 'Default-text för totalbelopp av ett nuvarande kreditlån i formulär.',
  },
  loanCalculatorTotalLoanAmountSuffix: {
    id: 'sambla.se.loanCalculator.totalLoanAmountSuffix',
    defaultMessage: 'SEK',
    description: 'Suffix-text för totalbelopp av ett nuvarande kreditlån i formulär.',
  },
  loanCalculatorTotalLoanAmountTooltip: {
    id: 'sambla.se.loanCalculator.totalLoanAmountTooltip',
    defaultMessage: 'Please enter the total loan amount for the loan you want to use for calculation',
    description: 'Tooltip-text för totalbelopp av ett nuvarande kreditlån i formulär.',
  },
  loanCalculatorInterestRateLabel: {
    id: 'sambla.se.loanCalculator.interestRateLabel',
    defaultMessage: 'Interest rate',
    description: 'Label for interest rate.',
  },
  loanCalculatorInterestRatePlaceholder: {
    id: 'sambla.se.loanCalculator.interestRatePlaceholder',
    defaultMessage: 'X.XX',
    description: 'Default text for interest rate.',
  },
  loanCalculatorInterestRateSuffix: {
    id: 'sambla.se.loanCalculator.interestRateSuffix',
    defaultMessage: '%',
    description: 'Suffix for interest rate.',
  },
  loanCalculatorInterestRateTooltip: {
    id: 'sambla.se.loanCalculator.interestRateTooltip',
    defaultMessage: 'The interest rate on loans can be seen as a monthly fee to the banks for lending you the money. Our partners offered interest rate is decided based on your financial situation and can vary. You can read more about effective interest rate <a>here.</a>',
    description: 'Tooltip for interest rate for the loan in this form.',
  },
  loanCalculatorRepaymentTimeLabel: {
    id: 'sambla.se.loanCalculator.repaymentTimeLabel',
    defaultMessage: 'Repayment time',
    description: 'Label for repayment time inside form',
  },
  loanCalculatorRepaymentTimePlaceholder: {
    id: 'sambla.se.loanCalculator.repaymentTimePlaceholder',
    defaultMessage: '0',
    description: 'Default text for repayment time inside form',
  },
  loanCalculatorRepaymentTimeSuffix: {
    id: 'sambla.se.loanCalculator.repaymentTimeSuffix',
    defaultMessage: 'years',
    description: 'Suffix for repayment time inside form',
  },
  loanCalculatorRepaymentTimeTooltip: {
    id: 'sambla.se.loanCalculator.repaymentTimeTooltip',
    defaultMessage: 'The repayment time is how long your loan is planned to be repaid. Depending on the time, the monthly cost of your loan might change, where shorter repayment time often means a higher monthly cost.',
    description: 'Tooltip for repayment time inside form, asking the applicant to fill in how long they wish to take to repay their loan. Also informing them that the longer time of repayment, the lower the monthly cost',
  },
  loanCalculatorSetUpFeeLabel: {
    id: 'sambla.se.loanCalculator.setUpFeeLabel',
    defaultMessage: 'Set-up Fee',
    description: 'Label text for start up fee.',
  },
  loanCalculatorSetUpFeePlaceholder: {
    id: 'sambla.se.loanCalculator.setUpFeePlaceholder',
    defaultMessage: 'SEK 300',
    description: 'Placeholder text for start up fee.',
  },
  loanCalculatorSetUpFeeTooltip: {
    id: 'sambla.se.loanCalculator.setUpFeeTooltip',
    defaultMessage: 'Some banks have a single set-up fee and/or recurring avia fees connected to your loan. You can usually find this info in the terms documentation of the loan or on the invoice.',
    description: 'Tooltip text for start up fee.',
  },
  loanCalculatorAviaFeeLabel: {
    id: 'sambla.se.loanCalculator.aviaFeeLabel',
    defaultMessage: 'Avia Fee',
    description: 'Label text for fee.',
  },
  loanCalculatorAviaFeePlaceholder: {
    id: 'sambla.se.loanCalculator.aviaFeePlaceholder',
    defaultMessage: 'SEK 25',
    description: 'Label text for avia fee.',
  },
  loanCalculatorAviaFeeTooltip: {
    id: 'sambla.se.loanCalculator.aviaFeeTooltip',
    defaultMessage: 'Some banks have a single set-up fee and/or recurring avia fees connected to your loan. You can usually find this info in the terms documentation of the loan or on the invoice.',
    description: 'Tooltip text for avia fee.',
  },
  loanCalculatorNextSlideButtonHeading: {
    id: 'sambla.se.loanCalculator.nextSlideButtonHeading',
    defaultMessage: 'Do you want to apply for a loan based on the calculation above?',
    description: 'Title above the teleporter',
  },
  loanCalculatorNextSlideButtonSubHeading: {
    id: 'sambla.se.loanCalculator.nextSlideButtonSubHeading',
    defaultMessage: 'Fill out the information on the next page',
    description: 'Subtitle above the teleporter',
  },
  loanCalculatorNextSlideButton: {
    id: 'sambla.se.loanCalculator.nextSlideButton',
    defaultMessage: 'To application',
    description: 'general progress button label',
  },
  loanCalculatorAmortizationTypeLabel: {
    id: 'sambla.se.loanCalculator.summaryPage.amortizationTypeLabel',
    defaultMessage: 'Type of amortization',
    description: 'Label for which type of amortization the user wants to calculate with',
  },
  loanCalculatorAmortizationTypeAnnuity: {
    id: 'sambla.se.loanCalculator.summaryPage.amortizationTypeAnnuity',
    defaultMessage: 'Annuity',
    description: 'Text for amortization type annuity',
  },
  loanCalculatorAmortizationTypeStraight: {
    id: 'sambla.se.loanCalculator.summaryPage.amortizationTypeStraight',
    defaultMessage: 'Straight',
    description: 'Text for amortization type straight',
  },
  loanCalculatorAmortizationTypeTooltip: {
    id: 'sambla.se.loanCalculator.summaryPage.amortizationTypeTooltip',
    defaultMessage: 'Amortization essentially means that you pay back a small portion of the loan every month. Samblas partners can provide either Annuity loans or Straight loans. Annuity loans are the most common and is where you pay the same monthly cost every month. You can read more about amortization <a>here.</a>',
    description: 'Text for tooltip',
  },
  loanCalculatorTotalCostsForLoan: {
    id: 'sambla.se.loanCalculator.summaryPage.totalCostsForLoan',
    defaultMessage: 'Total cost',
    description: 'Text for total costs',
  },
  loanCalculatorTotalCostsForLoanTooltip: {
    id: 'sambla.se.loanCalculator.summaryPage.totalCostsForLoanTooltip',
    defaultMessage: 'You need to pay back the whole sum of the loan through the amortization plan, but there are also fees and interest costs connected to the loan that are added and needs to be paid.',
    description: 'Text for tooltip',
  },
  loanCalculatorTotalCostsAndInterestAndFeesForLoan: {
    id: 'sambla.se.loanCalculator.summaryPage.totalCostsAndInterestAndFeesForLoan',
    defaultMessage: 'Interest and fees',
    description: 'Text for total costs and interest and fees for loan',
  },
  loanCalculatorTotalCostsAndInterestAndFeesForLoanTooltip: {
    id: 'sambla.se.loanCalculator.summaryPage.totalCostsAndInterestAndFeesForLoanTooltip',
    defaultMessage: 'These are the total fees and interest costs connected to the loan. This excludes the loan amount, and with it, the amortization.',
    description: 'Text for tooltip',
  },
  loanCalculatorMonthlyCost: {
    id: 'sambla.se.loanCalculator.summaryPage.monthlyCost',
    defaultMessage: 'Monthly cost:',
    description: 'Text monthly cost',
  },
  loanCalculatorMonthlyCostMonthOne: {
    id: 'sambla.se.loanCalculator.summaryPage.monthlyCostMonthOne',
    defaultMessage: 'Monthly cost, month 1:',
    description: 'Text monthly cost',
  },
  loanCalculatorMonthlyCostTooltip: {
    id: 'sambla.se.loanCalculator.summaryPage.monthlyCostTooltip',
    defaultMessage: 'This is the recurring monthly cost for you until you have paid back your loan. Depending on your amortization type, these costs might change with time.',
    description: 'Text for tooltip',
  },
  loanCalculatorMonthlyCostOverviewButtonText: {
    id: 'sambla.se.loanCalculator.summaryPage.monthlyCostOverviewButtonText',
    defaultMessage: 'See overview of monthly cost',
    description: 'Text for button',
  },

  loanCalculatorSummaryTablePageDescription: {
    id: 'sambla.se.loanCalculator.summaryPage.summaryTablePageDescription',
    defaultMessage: 'More info',
    description: 'Text for page description',
  },
  loanCalculatorEffectiveInterest: {
    id: 'sambla.se.loanCalculator.summaryPage.effectiveInterest',
    defaultMessage: 'Effective interest:',
    description: 'Text for summary table effective interest',
  },
  loanCalculatorSummaryTableColumnMonth: {
    id: 'sambla.se.loanCalculator.summaryPage.summaryTableColumnMonth',
    defaultMessage: 'Month',
    description: 'Text for summary table month column',
  },
  loanCalculatorSummaryTableColumnDebtBeforeAmortization: {
    id: 'sambla.se.loanCalculator.summaryPage.summaryTableColumnDebtBeforeAmortization',
    defaultMessage: 'Debt before amortization',
    description: 'Text for summary table debt before amortization column',
  },
  loanCalculatorSummaryTableColumnAmortization: {
    id: 'sambla.se.loanCalculator.summaryPage.summaryTableColumnAmortization',
    defaultMessage: 'Amortization',
    description: 'Text for summary table amortization column',
  },
  loanCalculatorSummaryTableColumnInterestCost: {
    id: 'sambla.se.loanCalculator.summaryPage.summaryTableColumnInterestCost',
    defaultMessage: 'Interest',
    description: 'Text for summary table interest cost column',
  },
  loanCalculatorSummaryTableColumnFeeCost: {
    id: 'sambla.se.loanCalculator.summaryPage.summaryTableColumnFeeCost',
    defaultMessage: 'Fees',
    description: 'Text for summary table fee cost column',
  },
  loanCalculatorSummaryTableColumnToPay: {
    id: 'sambla.se.loanCalculator.summaryPage.summaryTableColumnToPay',
    defaultMessage: 'To pay',
    description: 'Text for summary table to pay column',
  },
  loanCalculatorCurrencySuffix: {
    id: 'sambla.se.loanCalculator.summaryPage.currencySuffix',
    defaultMessage: 'SEK',
    description: 'Suffix text for amounts in the loan calculator etc. kr or sek',
  },
  feesCostRequired: {
    id: 'sambla.se.loanCalculator.feesCostRequired',
    defaultMessage: 'Please enter the fee',
    description: 'Fee description',
  },
  feesCostOnlyDigits: {
    id: 'sambla.se.loanCalculator.feesCostOnlyDigits',
    defaultMessage: 'Please write your fees cost in numbers only',
    description: 'Inform the customer that they may only pass in digits in the input for the fees cost.',
  },
  feesCostTooHigh: {
    id: 'sambla.se.loanCalculator.feesCostTooHigh',
    defaultMessage: 'Your fees cost cannot be more than {maxCost, number} SEK/mth.',
    description: 'Inform the customer that we may unfortunatly only accept a fees cost of "x" and that their cost is to high',
  },
  feesCostTooLow: {
    id: 'sambla.se.loanCalculator.feesCostTooLow',
    defaultMessage: 'Please enter the fees connected to the loan',
    description: 'Inform the customer that their passed in fees cost is set to low, for example "0"',
  },
  feesCostStartWithZero: {
    id: 'sambla.se.loanCalculator.feesCostStartWithZero',
    defaultMessage: "Please don't start your input with a 0",
    description: 'Inform the customer that their passed in fees cost is set to low, for example "0"',
  },
  mortgageValidatePresence: {
    id: 'sambla.se.mortgage.validatePresence',
    defaultMessage: 'This field is required',
    description: 'Error message when a required field is missing a value',
  },
  mortgageValidateNumericalityIsRequired: {
    id: 'sambla.se.mortgage.validateNumericality.isRequired',
    defaultMessage: 'This field is required',
    description: 'Error message when a required number field is missing a value',
  },
  mortgageValidateNumericalityOnlyNumbers: {
    id: 'sambla.se.mortgage.validateNumericality.onlyNumbers',
    defaultMessage: 'This field must be a number',
    description: 'Error message when a number field contains a non-numerical value',
  },
  mortgageValidateNumericalityMustBeAbove: {
    id: 'sambla.se.mortgage.validateNumericality.mustBeAbove',
    defaultMessage: 'This field must be greater than {min}',
    description: 'Error message when a number field is too low',
  },
  mortgageValidateNumericalityMustBeBelow: {
    id: 'sambla.se.mortgage.validateNumericality.mustBeBelow',
    defaultMessage: 'This field must be less than {max}',
    description: 'Error message when a number field is too high',
  },
  mortgageInputSuffixSEK: {
    id: 'sambla.se.mortgage.inputSuffix.SEK',
    defaultMessage: 'SEK',
    description: 'Form input suffix for Swedish kronor',
  },
  mortgageInputSuffixSEKPerMonth: {
    id: 'sambla.se.mortgage.inputSuffix.SEKPerMonth',
    defaultMessage: 'SEK/mth',
    description: 'Form input suffix for Swedish kronor per month',
  },
  mortgageInputSuffixPercent: {
    id: 'sambla.se.mortgage.inputSuffix.percent',
    defaultMessage: '%',
    description: 'Form input suffix for percentages',
  },
  mortgageInputSuffixSquareMetres: {
    id: 'sambla.se.mortgage.inputSuffix.squareMetres',
    defaultMessage: 'sq m',
    description: 'Form input suffix for square metres',
  },
  mortgageSlideTitleProperty: {
    id: 'sambla.se.mortgage.slideTitle.property',
    defaultMessage: 'About the property',
    description: 'Title text for the mortgage form property slide',
  },
  mortgageSlideTitleApplicant: {
    id: 'sambla.se.mortgage.slideTitle.applicant',
    defaultMessage: 'About the main applicant',
    description: 'Title text for the mortgage form applicant slide',
  },
  mortgageSlideTitleCoApplicant: {
    id: 'sambla.se.mortgage.slideTitle.coApplicant',
    defaultMessage: 'About the co-applicant',
    description: 'Title text for the mortgage form co-applicant slide',
  },
  mortgageSlideTitleHousehold: {
    id: 'sambla.se.mortgage.slideTitle.household',
    defaultMessage: 'About the household',
    description: 'Title text for the mortgage form household slide',
  },
  mortgageSlideTitleFinances: {
    id: 'sambla.se.mortgage.slideTitle.finances',
    defaultMessage: 'About the loan situation',
    description: 'Title text for the mortgage form finances slide',
  },
  mortgagePurposeLabel: {
    id: 'sambla.se.mortgage.loanPurposeLabel',
    defaultMessage: 'How can we help you?',
    description: 'Label for the type of mortgage application',
  },
  currentMortgageAmountLabel: {
    id: 'sambla.se.mortgage.currentMortgageAmountLabel',
    defaultMessage: 'Current mortgage amount',
    description: 'Label for the current mortgage loan amount',
  },
  currentMortgageAmountPlaceholder: {
    id: 'sambla.se.mortgage.currentMortgageAmountPlaceholder',
    defaultMessage: 'E.g. 1 250 000',
    description: 'Placeholder for the current mortgage loan amount',
  },
  currentMortgageCreditorLabel: {
    id: 'sambla.se.mortgage.currentMortgageCreditorLabel',
    defaultMessage: 'Current bank',
    description: 'Label for the current creditor of the mortgage',
  },
  currentMortgageInterestLabel: {
    id: 'sambla.se.mortgage.currentMortgageInterestLabel',
    defaultMessage: 'Current interest rate',
    description: 'Label for the current interest rate of the mortgage',
  },
  currentMortgageInterestPlaceholder: {
    id: 'sambla.se.mortgage.currentMortgageInterestPlaceholder',
    defaultMessage: 'E.g. 2.09',
    description: 'Placeholder for the current interest rate of the mortgage',
  },
  currentMortgageMonthlyPaymentLabel: {
    id: 'sambla.se.mortgage.currentMortgageMonthlyPaymentLabel',
    defaultMessage: 'Monthly payment',
    description: 'Label for the monthly payment of the mortgage',
  },
  currentMortgageMonthlyPaymentPlaceholder: {
    id: 'sambla.se.mortgage.currentMortgageMonthlyPaymentPlaceholder',
    defaultMessage: 'E.g. 5 000',
    description: 'Placeholder for the monthly payment of the mortgage',
  },
  currentMortgageMonthlyPaymentTooltip: {
    id: 'sambla.se.mortgage.currentMortgageMonthlyPaymentTooltip',
    defaultMessage: 'Enter the monthly amount you pay the bank for your mortgage',
    description: 'Tooltip for the monthly payment of the mortgage',
  },
  totalConsumerLoansLabel: {
    id: 'sambla.se.mortgage.totalConsumerLoansLabel',
    defaultMessage: 'Enter the total amount for existing consumer loans',
    description: 'Label for total existing unsecured loan amount',
  },
  totalConsumerLoansPlaceholder: {
    id: 'sambla.se.mortgage.totalConsumerLoansPlaceholder',
    defaultMessage: 'E.g. 250 000',
    description: 'Placeholder for total existing unsecured loan amount',
  },
  totalConsumerLoansTooltip: {
    id: 'sambla.se.mortgage.totalConsumerLoansTooltip',
    defaultMessage: 'Enter the sum of loans on all private loans or installment loans you have today',
    description: 'Tooltip for total existing unsecured loan amount',
  },
  totalCarLoansLabel: {
    id: 'sambla.se.mortgage.totalCarLoansLabel',
    defaultMessage: 'Enter the total amount for existing car loans',
    description: 'Label for total existing car loan amount',
  },
  totalCarLoansPlaceholder: {
    id: 'sambla.se.mortgage.totalCarLoansPlaceholder',
    defaultMessage: 'E.g. 75 000',
    description: 'Placeholder for total existing car loan amount',
  },
  totalCarLoansTooltip: {
    id: 'sambla.se.mortgage.totalCarLoansTooltip',
    defaultMessage: 'Enter the sum of all car loans you have today',
    description: 'Tooltip for total existing car loan amount',
  },
  totalCreditCardDebtLabel: {
    id: 'sambla.se.mortgage.totalCreditCardDebtLabel',
    defaultMessage: 'Enter total amount of existing credit card debt',
    description: 'Label for total existing credit card debt amount',
  },
  totalCreditCardDebtPlaceholder: {
    id: 'sambla.se.mortgage.totalCreditCardDebtPlaceholder',
    defaultMessage: 'E.g. 15 000',
    description: 'Placeholder for total existing credit card debt amount',
  },
  totalCreditCardDebtTooltip: {
    id: 'sambla.se.mortgage.totalCreditCardDebtTooltip',
    defaultMessage: 'Enter the sum of all credit card debts you have today',
    description: 'Tooltip for total existing credit card debt amount',
  },
  totalOtherLoansLabel: {
    id: 'sambla.se.mortgage.totalOtherLoansLabel',
    defaultMessage: 'Enter the total amount of any other loans',
    description: 'Label for total existing other loan amount',
  },
  totalOtherLoansPlaceholder: {
    id: 'sambla.se.mortgage.totalOtherLoansPlaceholder',
    defaultMessage: 'E.g. 50 000',
    description: 'Placeholder for total existing other loan amount',
  },
  totalOtherLoansTooltip: {
    id: 'sambla.se.mortgage.totalOtherLoansTooltip',
    defaultMessage: 'Enter the sum of all other loans you have today',
    description: 'Tooltip for total existing other loan amount',
  },
  propertyTypeLabel: {
    id: 'sambla.se.mortgage.propertyTypeLabel',
    defaultMessage: 'What type of property is it?',
    description: 'Asking customer what type of property they own',
  },
  propertyEstimatedValueLabel: {
    id: 'sambla.se.mortgage.propertyEstimatedValueLabel',
    defaultMessage: 'Estimated property value',
    description: 'Label for the estimated value of the property',
  },
  propertyEstimatedValuePlaceholder: {
    id: 'sambla.se.mortgage.propertyEstimatedValuePlaceholder',
    defaultMessage: 'E.g. 1 500 000',
    description: 'Placeholder for the estimated value of the property',
  },
  propertyDownPaymentLabel: {
    id: 'sambla.se.mortgage.propertyDownPaymentLabel',
    defaultMessage: 'Down payment',
    description: 'Label for the down payment of the property',
  },
  propertyDownPaymentPlaceholder: {
    id: 'sambla.se.mortgage.propertyDownPaymentPlaceholder',
    defaultMessage: 'E.g. 250 000',
    description: 'Placeholder for the downpayment of the property',
  },
  propertyDownPaymentTooltip: {
    id: 'sambla.se.mortgage.propertyDownPaymentTooltip',
    defaultMessage: 'The down payment of the property',
    description: 'Tooltip for the downpayment of the property',
  },
  propertyOperatingCostLabel: {
    id: 'sambla.se.mortgage.propertyOperatingCostLabel',
    defaultMessage: 'Operating cost',
    description: 'Label for the monthly operating cost of the property',
  },
  propertyOperatingCostPlaceholder: {
    id: 'sambla.se.mortgage.propertyOperatingCostPlaceholder',
    defaultMessage: 'E.g. 5 000',
    description: 'Placeholder for the monthly operating cost of the property',
  },
  propertyOperatingCostTooltip: {
    id: 'sambla.se.mortgage.propertyOperatingCostTooltip',
    defaultMessage: 'Enter the total amount you pay for heat, water, electricity, garbage disposal, draining, and insurance',
    description: 'Tooltip for the monthly operating cost of the property',
  },
  propertyMonthlyFeeLabel: {
    id: 'sambla.se.mortgage.propertyMonthlyFeeLabel',
    defaultMessage: 'Monthly fee',
    description: 'Label for the monthly fee of the property',
  },
  propertyMonthlyFeePlaceholder: {
    id: 'sambla.se.mortgage.propertyMonthlyFeePlaceholder',
    defaultMessage: 'E.g. 5 000',
    description: 'Placeholder for the monthly fee of the property',
  },
  propertyMonthlyFeeTooltip: {
    id: 'sambla.se.mortgage.propertyMonthlyFeeTooltip',
    defaultMessage: 'Enter the monthly operating cost of the property, including the housing association fee if applicable. This does not include amortisation of current loan.',
    description: 'Tooltip for the monthly housing fee of the property',
  },
  propertyLivingSpaceLabel: {
    id: 'sambla.se.mortgage.propertyLivingSpaceLabel',
    defaultMessage: 'Living space',
    description: 'Label for the living space of the property',
  },
  propertyLivingSpacePlaceholder: {
    id: 'sambla.se.mortgage.propertyLivingSpacePlaceholder',
    defaultMessage: 'E.g. 45',
    description: 'Placeholder for the living space of the property',
  },
  propertyAddressLabel: {
    id: 'sambla.se.mortgage.propertyAddressLabel',
    defaultMessage: 'Address',
    description: 'Label for the address of the property',
  },
  propertyAddressPlaceholder: {
    id: 'sambla.se.mortgage.propertyAddressPlaceholder',
    defaultMessage: 'E.g. Storgatan 1',
    description: 'Placeholder for the address of the property',
  },
  propertyZipCodeLabel: {
    id: 'sambla.se.mortgage.propertyZipCodeLabel',
    defaultMessage: 'Zip code',
    description: 'Label for the zip code of the property',
  },
  propertyZipCodePlaceholder: {
    id: 'sambla.se.mortgage.propertyZipCodePlaceholder',
    defaultMessage: 'E.g. 123 45',
    description: 'Placeholder for the zip code of the property',
  },
  propertyCityNameLabel: {
    id: 'sambla.se.mortgage.propertyCityNameLabel',
    defaultMessage: 'City',
    description: 'Label for the city of the property',
  },
  propertyCityNamePlaceholder: {
    id: 'sambla.se.mortgage.propertyCityNamePlaceholder',
    defaultMessage: 'E.g. Stockholm',
    description: 'Placeholder for the city of the property',
  },
  propertyMunicipalityNameLabel: {
    id: 'sambla.se.mortgage.propertyMunicipalityNameLabel',
    defaultMessage: 'District',
    description: 'Label for the municipality of the property',
  },
  propertyMunicipalityNamePlaceholder: {
    id: 'sambla.se.mortgage.propertyMunicipalityNamePlaceholder',
    defaultMessage: 'E.g. Nacka',
    description: 'Placeholder for the municipality of the property',
  },
  propertyMunicipalityNameTooltip: {
    id: 'sambla.se.mortgage.propertyMunicipalityNameTooltip',
    defaultMessage: 'Enter property district name',
    description: 'Tooltip for the municipality of the property',
  },
  propertyNumberOfRoomsLabel: {
    id: 'sambla.se.mortgage.propertyNumberOfRoomsLabel',
    defaultMessage: 'Number of rooms',
    description: 'Label for the number of rooms in the property',
  },
  propertyNumberOfRoomsPlaceholder: {
    id: 'sambla.se.mortgage.propertyNumberOfRoomsPlaceholder',
    defaultMessage: 'E.g. 4',
    description: 'Placeholder for the number of rooms in the property',
  },
  carsOwnedLabel: {
    id: 'sambla.se.mortgage.carsOwnedLabel',
    defaultMessage: 'How many cars do you own?',
    description: 'Label for number of cars owned',
  },
  carsOwnedMonthlyCostLabel: {
    id: 'sambla.se.mortgage.carsOwnedMonthlyCostLabel',
    defaultMessage: 'Enter the total cost of your owned cars',
    description: 'Label for total monthly cost of owned cars',
  },
  carsOwnedMonthlyCostPlaceholder: {
    id: 'sambla.se.mortgage.carsOwnedMonthlyCostPlaceholder',
    defaultMessage: 'E.g. 4 000',
    description: 'Placeholder for total monthly cost of owned cars',
  },
  carsOwnedMonthlyCostTooltip: {
    id: 'sambla.se.mortgage.carsOwnedMonthlyCostTooltip',
    defaultMessage: 'Enter the total monthly cost for your owned cars, including insurance and fuel',
    description: 'Tooltip for total monthly cost of owned cars',
  },
  carsLeasedLabel: {
    id: 'sambla.se.mortgage.carsLeasedLabel',
    defaultMessage: 'How many cars are you leasing?',
    description: 'Label for number of cars leased',
  },
  carsLeasedMonthlyCostLabel: {
    id: 'sambla.se.mortgage.carsLeasedMonthlyCostLabel',
    defaultMessage: 'Enter the total cost of your leased cars',
    description: 'Label for total monthly cost of leased cars',
  },
  carsLeasedMonthlyCostPlaceholder: {
    id: 'sambla.se.mortgage.carsLeasedMonthlyCostPlaceholder',
    defaultMessage: 'E.g. 4 000',
    description: 'Placeholder for total monthly cost of leased cars',
  },
  carsLeasedMonthlyCostTooltip: {
    id: 'sambla.se.mortgage.carsLeasedMonthlyCostTooltip',
    defaultMessage: 'Enter the total monthly cost for your leased cars, including insurance and fuel',
    description: 'Tooltip for total monthly cost of leased cars',
  },
  zipCodeIsRequired: {
    id: 'sambla.se.mortgage.zipCodeIsRequired',
    defaultMessage: 'This field is required',
    description: 'Field validation error when zip code is missing',
  },
  zipCodeMustMatchFormatSE: {
    id: 'sambla.se.mortgage.zipCodeMustMatchFormatSE',
    defaultMessage: 'Zip code must match format XXX XX',
    description: 'Field validation error when zip code is badly formatted',
  },
  duplicateApplicationErrorMessage: {
    id: 'sambla.se.submitError.duplicateApplication',
    defaultMessage: 'It looks like you already have a valid application. If you want to make changes to the application, please contact our customer service on',
    description: 'Text for the error message that is displayed to the customer when they already have a active application.',
  },
  errorMessageTitle: {
    id: 'sambla.se.submitError.title',
    defaultMessage: 'Wops!',
    description: 'Title for the error that is displayed on submit.',
  },
  newLoanLimitWarning: {
    id: 'sambla.se.newLoanLimitWarning',
    defaultMessage: 'Note! A loan amount over 600k requires you to refinance/collect loans and credits you already have.',
    description: 'warning for when new loan amount is over 600k',
  },
  warningDisclaimerTitle: {
    id: 'sambla.se.warningDisclaimerTitle',
    defaultMessage: 'Borrowing Money Costs!',
    description: 'title for the warning disclaimer',
  },
  warningDisclaimerText: {
    id: 'sambla.se.warningDisclaimerText',
    defaultMessage: 'If you cannot repay the entire debt, you risk a payment notice. This can lead to difficulties in renting a home, taking out subscriptions and getting new loans. If you need to talk to someone about your finances, you can turn to the budget and debt counseling in your municipality. Contact information is available at konsumerverket.se.',
    description: 'text for the warning disclaimer',
  },
});

export default messages;
