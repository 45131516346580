import React from 'react';
import { useSelector } from 'react-redux';
import { MonthlyFixedPaymentLoan } from '@advisa/loan-calculator';
import styled, { css } from 'styled-components';

import PropTypes from 'prop-types';
import { useOptions } from '../../../context/options';
import CalculateInterestRate from '../../molecules/CalculateInterest';
import style from './style';
import localeData from '../../../js/localizations/locale-data';

const Wrapper = styled.div`
  &&{
    display: block;
    margin: 1rem auto;
    background-image: ${(props) => props.background};
    max-width: ${style.disclaimerMaxWidth};
    margin: auto;
  }
`;

const DisclaimerText = styled.p`
  &&{
    margin: auto;
    text-align: ${style.disclaimerTextAlign};
    font-family: var(--font-family);
    font-size: 0.8rem;
    line-height: 1.5;
    color: ${style.disclaimerColor};

    ${(props) => props.aboveFold && css`
      color: ${style.aboveFoldColor};
    `}
  }
`;

const Disclaimer = ({
  isAboveFold,
}) => {
  const { optionVariables } = useOptions();
  const disclaimer = optionVariables.dynamicFormDisclaimer.text;
  const { administrationFee } = optionVariables.dynamicFormDisclaimer?.administrationFee
    ? optionVariables.dynamicFormDisclaimer : 0;
  const { arrangementFee } = optionVariables.dynamicFormDisclaimer?.arrangementFee
    ? optionVariables.dynamicFormDisclaimer : 0;
  const formState = useSelector((state) => state.form.application)
    || localeData.MERGE_LOAN_FORM_DEFAULT_VALUES;
  const values = formState.totalAmount ? formState : localeData.MERGE_LOAN_FORM_DEFAULT_VALUES;

  const interestRate = CalculateInterestRate();

  const calculatedLoan = new MonthlyFixedPaymentLoan({
    amount: values.totalAmount,
    repaymentTime: values.repaymentTime * 12,
    interest: interestRate,
    arrangementFee,
    administrationFee,
  });

  const isValid = calculatedLoan.isValid();

  const pureCost = isValid ? (calculatedLoan.getTotalCost() - calculatedLoan.amount).toFixed(0) : 0;

  const dynamicDisclaimer = disclaimer
    .replace(/{totalAmount}/g, isValid ? calculatedLoan.amount : 0)
    .replace(/{repaymentTimeYears}/g, calculatedLoan.repaymentTime / 12)
    .replace(/{apr}/g, isValid ? (calculatedLoan.getAPR() * 100).toFixed(2) : 0)
    .replace(/{interestRate}/g, (calculatedLoan.interest * 100).toFixed(2))
    .replace(/{cost}/g, isValid ? pureCost : 0)
    .replace(/{monthlyCost}/g, isValid
      ? Math.round(calculatedLoan.getMonthlyPayment()) : 0)
    .replace(/{numPayments}/g, calculatedLoan.repaymentTime)
    .replace(/{totalCost}/g, isValid ? (calculatedLoan.getTotalCost()).toFixed(0) : 0)
    .replace(/{arrangementFee}/g, isValid && arrangementFee)
    .replace(/{administrationFee}/g, isValid && administrationFee);

  return (
    <Wrapper>
      <DisclaimerText aboveFold={isAboveFold}>
        {' '}
        {dynamicDisclaimer}
        {' '}
      </DisclaimerText>
    </Wrapper>
  );
};

Disclaimer.propTypes = {
  isAboveFold: PropTypes.bool,
};

Disclaimer.defaultProps = {
  isAboveFold: false,
};

export default Disclaimer;
